import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material';
import {BookingAddDialogComponent} from '../../booking-add-dialog/booking-add-dialog.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BookingsService} from '../../../_services/bookings.service';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-sold-dialog',
  templateUrl: './sold-dialog.component.html',
  styleUrls: ['./sold-dialog.component.css']
})
export class SoldDialogComponent implements OnInit {


  updateForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<SoldDialogComponent>,
    private bookingsService: BookingsService) {
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    const fields = this.updateForm.value;

    this.bookingsService.update({
      'sale_price': fields.sale_price,
      'num_offers_prior_to_auction': fields.num_offers_prior_to_auction,
      'num_registered_bidders': fields.num_registered_bidders,
      'estimated_escrow_close_date': fields.estimated_escrow_close_date
    });

    this.dialogRef.close();

  }

  ngOnInit() {
    this.updateForm = new FormGroup({
      'id': new FormControl(this.bookingsService.bookingId),
      'sale_price': new FormControl(this.bookingsService.currentBooking.sale_price),
      'num_registered_bidders': new FormControl(this.bookingsService.currentBooking.num_registered_bidders),
      'num_offers_prior_to_auction': new FormControl(this.bookingsService.currentBooking.num_offers_prior_to_auction),
      'estimated_escrow_close_date': new FormControl(this.bookingsService.currentBooking.estimated_escrow_close_date),
    });
  }

}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {AuctionEvent} from '../_models/auction-event';
import {Subject} from 'rxjs';


@Injectable()
export class AuctionEventsService {

  eventsChanged = new Subject<AuctionEvent[]>();
  auctionEvents: AuctionEvent[];

  constructor(private http: HttpClient,
              private authService: AuthService) {

  }

  findEvents() {
    this.http.get(`${environment.apiEndPoint}events`, {
      headers: this.authService.jwt()
    }).subscribe(res => {
      this.auctionEvents = res['events'];
      this.eventsChanged.next(this.auctionEvents);
    });
  }


}

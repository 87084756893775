import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, of} from 'rxjs';

import {BehaviorSubject} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';


import {OpenHousesService} from '../open-houses.service';
import {OpenHouse} from '../../_models/open-house';

export class OpenHousesDatasource implements DataSource<OpenHouse> {
  private dataSubject = new BehaviorSubject<OpenHouse[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  constructor(private openHousesServices: OpenHousesService) {

  }

  loadData(bookingId: number) {

    this.loadingSubject.next(true);

    this.openHousesServices.findOpenHouses(bookingId).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(data => {
        this.dataSubject.next(data);
      });

  }

  connect(collectionViewer: CollectionViewer): Observable<OpenHouse[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

}


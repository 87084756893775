import {Injectable} from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import {Router} from '@angular/router';

// import { Http, Headers, Response, RequestOptions } from '@angular/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {User} from '../_models/user';
import {HttpClient, HttpHeaders} from '@angular/common/http';


export const TOKEN_NAME: string = 'currentUser';

@Injectable()
export class AuthService {


  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private router: Router, private httpClient: HttpClient) {
  }

  getToken(): string {
    /*if (localStorage.getItem(TOKEN_NAME) == null) {
      //this.logout();
    }*/
    if (localStorage.getItem(TOKEN_NAME)) {
      const currentUser = JSON.parse(localStorage.getItem(TOKEN_NAME));
      // if (currentUser.token == null) this.logout();
      return currentUser.token;
    }
  }

  getUser(): any {
    const currentUser = JSON.parse(localStorage.getItem(TOKEN_NAME));
    return currentUser;
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_NAME, token);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) {
      token = this.getToken();
    }
    if (!token) {
      return true;
    }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  login(email: string, password: string): Promise<any> {

    return this.httpClient
      .post(`${environment.apiEndPointUnAuth}login`,
        JSON.stringify({email: email, password: password}),
        {headers: this.headers})
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  forgotPassword(email:string):Promise<any>{
      return this.httpClient
      .post(`${environment.apiEndPointUnAuth}forgotPassword`,
        JSON.stringify({email: email}),
        {headers: this.headers})
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

resetPassword(password:string,token:string):Promise<any>{
      return this.httpClient
      .post(`${environment.apiEndPointUnAuth}resetPassword`,
        JSON.stringify({password: password,token:token}),
        {headers: this.headers})
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }


  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(TOKEN_NAME); // already exist line
    localStorage.clear();
    //this.router.navigate(['login']);
    this.router.navigate(['/auth', 'login']);
    //return false;
  }

  signup(fields: any): Promise<any> {

    return this.httpClient
      .post(`${environment.apiEndPointUnAuth}signup`,
        JSON.stringify(fields),
        {headers: this.headers})
      .toPromise()
      .then((res) => res)
      .catch(this.handleSignupError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  private handleSignupError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only

    return Promise.reject(error.message);
  }

  public jwt() {
    return new HttpHeaders({'Authorization': 'Bearer ' + this.getToken()});
  }

}

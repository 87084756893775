import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import {UsersService} from '../../_services/users.service';
import {AuthService} from '../../_services/auth.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  ResetPassForm: FormGroup;
  loading = false;
  submitted = false;
  token:any;
  constructor(private router: Router,
  private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private usersService: UsersService,
              private authService: AuthService,
              private toastr: ToastrService) {

  }

  ngOnInit() {

  	 this.token = this.route.snapshot.paramMap.get("token");
  	 //alert(this.token)

   /* if (typeof this.usersService.userLoggedIn !== 'undefined' && this.usersService.userLoggedIn) {
      this.router.navigate(['bookings']);
    }*/

    this.usersService.userLoggedIn = false;

    this.ResetPassForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
    },{validator: this.checkPasswords});
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.ResetPassForm.controls;
  }

checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  let pass = group.get('password').value;
  let confirmPass = group.get('confirm_password').value;

  return pass === confirmPass ? null : { notSame: true }     
}

  resetpassword() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.ResetPassForm.invalid) {
      this.toastr.error('Please Match Password and Confirm Password.', 'Reset Password');
      return;
    }

    this.loading = true;
    const fields = this.ResetPassForm.value;

    this.authService.resetPassword(fields.password,this.token)
      .then((res) => {
        const user = res; // login successful if there's a jwt token in the response
        if (user) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // to keep user logged in between page refreshes
          setTimeout(() => {
            this.router.navigate(['auth/login']);
            this.toastr.success('Password Successfully Reset!!', 'Reset Password');
          }, 1);
        }
      }).catch(
      (err) => {

        this.toastr.error('Token Not valid or Expire !! Please check', 'Could not found!');

        this.loading = false;

      });
  }

}

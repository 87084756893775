import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {Task} from '../_models/task';
import {AuthService} from './auth.service';
import {Booking} from '../_models/booking';
import { ErrorService } from '../_services/error.service';

@Injectable({
  providedIn: 'root'
})
export class TasksService {


  tasksLoaded = new Subject<Task[]>(); // an observable to notify others a new booking has been loaded

  results: Task[];

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    public errorService: ErrorService
  ) {
  }

  getLastResult() {
    return this.results;

  }

  getItemFromLastResult(index) {
    return this.results[index];
  }

  findTasks(params): Observable<Task[]> {

    const url = `${environment.apiEndPoint}tasks`;

    return this.http.get(url, {
      headers: this.authService.jwt(),
      params
    }).pipe(
      map(results => {
          this.results = results['data'];
          return results['data'];
        }
      )
    );
  }

  update(taskId: number, fields: {}): Promise<any> {
    return this.http.put(`${environment.apiEndPoint}tasks/${taskId}`,
      fields, {
        headers: this.authService.jwt()
      }
    )
      .toPromise()
      .then(res => {
        if (res) {
          this.tasksLoaded.next(this.results);
          return res;
        }
      })
      .catch(res => {
        this.errorService.displayError(res);
      });
  }


  insert(bookingId: number, fields: {}): Promise<any> {
    return this.http.post(`${environment.apiEndPoint}bookings/${bookingId}/tasks`,
      fields, {
        headers: this.authService.jwt()
      }
    )
      .toPromise()
      .then(res => {
        if (res) {
          // console.log(res);
          this.tasksLoaded.next(this.results);
          return res;
        }
      })
      .catch(res => {
        this.errorService.displayError(res);
      });
  }
}

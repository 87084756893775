import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';

import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';

import {UploadedPhoto} from '../_models/uploaded-photo';
import {OpenHouse} from '../_models/open-house';
import {BookingsService} from './bookings.service';


@Injectable()
export class UploadedPhotosService {

  results: UploadedPhoto[];
  resultsLoaded = new Subject<UploadedPhoto[]>();


  photoresults: UploadedPhoto[]; // will work to delete this

  constructor(private http: HttpClient,
              private authService: AuthService, private bookingsService: BookingsService) {

  }


  getPhotos(bookingId: number): Promise<any> {
    return this.http.get(`${environment.apiEndPoint}getphotos/${bookingId}`, {
        headers: this.authService.jwt()
      }
    ).toPromise()
      .then(res => {
        if (res) {
          this.results = res['photos'];
          this.resultsLoaded.next(this.results);
          //alert(this.results.length)
          //this.openHousesLoaded.next(this.results);
          this.bookingsService.updateBooking(this.results.length);
          return this.results;
        }
      })
      .catch(res => {
        //this.errorService.displayError(res);
      });
  }


  getLastResult() {
    return this.photoresults;

  }

  getItemFromLastResult(index) {
    // console.log(this.photoresults);
    return this.photoresults[index];
  }

  featurePhotos(booking_id: number, id: number) {

    return this.http.get(`${environment.apiEndPoint}featurephotos/${booking_id}/${id}`, {
      headers: this.authService.jwt()
    }).pipe(
      map(results => {
          // console.log(results);
          return results;
        }
      )
    );
  }

  updateSortOrder(booking_id: number, ids: string) {


    return this.http.put(`${environment.apiEndPoint}photos/${booking_id}/sort`,
      {
        ids: ids
      }, {
        headers: this.authService.jwt()
      }).pipe(
      map(results => {
          // console.log(results);
          return results;
        }
      )
    );
  }

  deletePhotos(id: number) {

    return this.http.get(`${environment.apiEndPoint}deletephotos/${id}`, {
      headers: this.authService.jwt()
    }).pipe(
      map(results => {
          // console.log(results);
          return results;
        }
      )
    );
  }

  getImage(bookingId, filename) {
    return this.http.get(`${environment.apiEndPoint}photos/getImageContents/${bookingId}/${filename}`,
      {
        headers: this.authService.jwt(),
        responseType: 'blob'
      }
    );
  }

}


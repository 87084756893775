import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import { Region } from '../_models/region';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class ListsService {

  dataChanged = new Subject<{listing_agents, regional_directors, booking_statuses, booking_states, regions, auction_events}>();
  data = {listing_agents: [], regional_directors: [], booking_statuses: [], booking_states: [], regions: [], auction_events: []}

  constructor(private http: HttpClient,
              private authService: AuthService) {

  }

  getLists() {
    this.http.get(`${environment.apiEndPoint}app/lists`, {
      headers: this.authService.jwt()
    }).subscribe(res => {
      const data = res['data'];
      this.data = data;
      this.dataChanged.next(this.data);
      return data;
    });
  }

  getData() {
    return this.data;
  }
}

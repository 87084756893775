import {AfterViewInit, Component, ElementRef, OnInit, Output, ViewChild} from '@angular/core';


@Component({
  selector: 'app-tasks-global',
  templateUrl: './tasks-global.component.html',
  styleUrls: ['./tasks-global.component.css']
})
export class TasksGlobalComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }

}

import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, of} from 'rxjs';

import {BehaviorSubject} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {Task} from '../../_models/task';
import {TasksService} from '../tasks.service';

export class TasksDatasource implements DataSource<Task> {

  private dataSubject = new BehaviorSubject<Task[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private tasksService: TasksService) {

  }

  loadData(params: object) {

    this.loadingSubject.next(true);

    this.tasksService.findTasks(params).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(data => {
        this.dataSubject.next(data);
      });

  }

  connect(collectionViewer: CollectionViewer): Observable<Task[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

}


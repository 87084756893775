import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {TasksService} from '../../../_services/tasks.service';
import {BookingsService} from '../../../_services/bookings.service';


@Component({
  selector: 'app-add-task-dialog',
  templateUrl: './add-task-dialog.component.html',
  styleUrls: ['./add-task-dialog.component.css']
})
export class AddTaskDialogComponent implements OnInit {

  editMode = false;

  addTasksForm: FormGroup;

  users: any[];


  constructor(
    private bookingsService: BookingsService,
    private tasksService: TasksService,
    private dialogRef: MatDialogRef<AddTaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {

    const editMode = this.editMode = (this.data == null) ? false : true;

    console.log('Task');
    console.log(editMode);

    // Push the Booking's Users
    this.users = this.bookingsService.currentBooking.users;


    this.addTasksForm = new FormGroup({
      'id': new FormControl(editMode ? this.data.id : ''),
      'assigned_to_user_id': new FormControl(editMode ? this.data.assigned_to_user_id * 1 : ''),
      'title': new FormControl(editMode ? this.data.title : ''),
      'start_date': new FormControl(editMode ? this.data.start_date : ''),
      'due_date': new FormControl(editMode ? this.data.due_date : ''),
      'description': new FormControl(editMode ? this.data.description : ''),
      'is_completed': new FormControl(editMode ? this.data.is_completed : '')
    });


  }


  onSaveTask() {

    const fields = this.addTasksForm.value;

    if (this.editMode) {
      this.tasksService.update(fields.id, fields);
    } else {
      this.tasksService.insert(this.bookingsService.bookingId, fields);
    }


    this.dialogRef.close();

  }

  onCancel() {

  }

  changeDueDate(newValue) {
    this.addTasksForm.get('start_date').setValue(newValue);
  }

}

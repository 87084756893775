import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {BookingsService} from '../../_services/bookings.service';
import {ActivatedRoute,CanDeactivate} from '@angular/router';
import {Booking} from '../../_models/booking';
import {AuctionEventsService} from '../../_services/auction-events.service';
import {AuctionEvent} from '../../_models/auction-event';
import {UtilitiesService} from '../../_services/utils/utilities.service';
import {UsersService} from '../../_services/users.service';
import {DialogService} from '../../_services/dialog.service';
import {Observable, of} from 'rxjs';

@Component({
  selector: 'app-booking-listing',
  templateUrl: './booking-listing.component.html',
  styleUrls: ['./booking-listing.component.css']
})
export class BookingListingComponent implements OnInit, OnDestroy {

  isAdmin = false;
  canEdit = false;
   Changes = false;
  myForm: FormGroup;

  booking: Booking;
  bookingLoaded;
  bookingId: number;

  allowSubmitForReview: boolean;

canDeactivate(): Observable<boolean> | boolean {

  if(this.Changes){
    return this.dialogService.confirm('Are you sure you want to leave this page? If you leave this page you will lose any unsaved changes.');
  }else{
    return true;
  }

}

  constructor(private usersService: UsersService,
              private fb: FormBuilder,
              private bookingsService: BookingsService,
              private utilitiesService: UtilitiesService,
              private route: ActivatedRoute,
              private dialogService: DialogService
  ) {
  }

  ngOnInit() {

    this.isAdmin = this.usersService.currentUserIsAdmin();

    if (typeof this.bookingsService.currentBooking !== 'undefined') {
      this.booking = this.bookingsService.currentBooking;
      this.allowSubmitForReview = this.bookingsService.allowSubmitForReview();
      this.canEdit = this.usersService.currentUserIsAdmin() || this.booking.booking_status_id !== 5;
      this.initForm();
    }

    this.bookingsService.bookingLoaded.subscribe(res => {
      this.bookingLoaded = true;
      this.booking = this.bookingsService.currentBooking;
      this.allowSubmitForReview = this.bookingsService.allowSubmitForReview();
      this.canEdit = this.usersService.currentUserIsAdmin() || this.booking.booking_status_id !== 5;
      this.initForm();

    });
  }

  ngOnDestroy() {
    console.log(`Destroying booking-listing ngOnInit`);
    // this.router.events.unsubscribe();
    // this.bookingsService.bookingLoaded.unsubscribe();

  }


  initForm() {
    const us = this.utilitiesService;
    this.myForm = this.fb.group({
      seller1_name: [this.booking.seller1_name, us.validatorsAlphaReq()],
      seller1_email: [this.booking.seller1_email, us.validatorsEmailReq()],
      seller1_phone: [this.booking.seller1_phone, us.validatorsPhoneReq()],
      seller2_name: [this.booking.seller2_name, us.validatorsAlpha()],
      seller2_email: [this.booking.seller2_email, us.validatorsEmail()],
      seller2_phone: [this.booking.seller2_phone, us.validatorsPhone()],
      mls_live_date: [us.cleanDateStr(this.booking.mls_live_date), us.validatorsReq()],
      bidding_reg_deadline_date: [us.cleanDateStr(this.booking.bidding_reg_deadline_date), us.validatorsReq()],
      starting_bid_price: [us.floatOrEmpty(this.booking.starting_bid_price), us.validatorsAmountReq()],
      deposit_amount: [us.floatOrEmpty(this.booking.deposit_amount), us.validatorsAmountReq()],
      escrow_co_name: [this.booking.escrow_co_name, us.validatorsAlphaReq()],
      escrow_co_rep_name: [this.booking.escrow_co_rep_name, us.validatorsAlphaReq()],
      escrow_co_rep_email: [this.booking.escrow_co_rep_email, us.validatorsEmailReq()],
      title_co_name: [this.booking.title_co_name, us.validatorsAlphaReq()],
      title_co_rep_name: [this.booking.title_co_rep_name, us.validatorsAlphaReq()],
      title_co_rep_email: [this.booking.title_co_rep_email, us.validatorsEmailReq()],
      nhd_co_name: [this.booking.nhd_co_name, us.validatorsAlphaReq()],
      nhd_rep_email: [this.booking.nhd_rep_email, us.validatorsEmailReq()],
      homewarranty_co_name: [this.booking.homewarranty_co_name, us.validatorsAlphaReq()],
      homewarranty_amount: [this.booking.homewarranty_amount, us.validatorsAmountReq()],
      hoa_applicable: [this.booking.hoa_applicable, us.validatorsAlphaReq()],
      melaroos: [this.booking.melaroos, us.validatorsAlphaReq()],
      property_type: [this.booking.property_type, us.validatorsAlphaReq()],

      midcampaign_mtg_date: [us.cleanDateStr(this.booking.midcampaign_mtg_date), us.validatorsReq()],
      weekly_agentseller_mtg_date: [us.cleanDateStr(this.booking.weekly_agentseller_mtg_date), us.validatorsReq()],
      regdirseller_call1_date: [us.cleanDateStr(this.booking.regdirseller_call1_date), us.validatorsReq()],
      regdirseller_call2_date: [us.cleanDateStr(this.booking.regdirseller_call2_date), us.validatorsReq()],
      reserveset_date: [us.cleanDateStr(this.booking.reserveset_date), us.validatorsReq()],
      reserve_price: [us.floatOrEmpty(this.booking.reserve_price), us.validatorsAmountReq()],

      midcampaign_mtg_time: [this.booking.midcampaign_mtg_time, us.validatorsReq()],
      weekly_agentseller_mtg_time: [this.booking.weekly_agentseller_mtg_time, us.validatorsReq()],
      regdirseller_call1_time: [this.booking.regdirseller_call1_time, us.validatorsReq()],
      regdirseller_call2_time: [this.booking.regdirseller_call2_time, us.validatorsReq()],
      reserveset_time: [this.booking.reserveset_time, us.validatorsReq()],

      occupancy: [this.booking.occupancy, us.validatorsReq()],
      auction_listing_agreement: [this.booking.auction_listing_agreement, us.validatorsReq()],
      auction_team_to_email_campaign_to_seller: [this.booking.auction_team_to_email_campaign_to_seller, us.validatorsReq()],
      seller_email_communication_trail: [this.booking.seller_email_communication_trail, us.validatorsReq()],
      comments: [this.booking.comments, us.validatorsReq()],

      square_feet: [this.booking.square_feet, us.validatorsReq()],
      bedrooms: [this.booking.bedrooms, us.validatorsReq()],
      bathrooms: [this.booking.bathrooms, us.validatorsReq()],
      sync_with_website: [this.booking.sync_with_website, us.validatorsReq()],
      website_description: [this.booking.website_description, us.validatorsReq()],
      website_url: [this.booking.website_url, us.validatorsReq()],
      website_url_short: [this.booking.website_url_short, us.validatorsReq()],
      region_for_website: [this.booking.region_for_website, us.validatorsReq()],
      property_video: [this.booking.property_video, us.validatorsReq()],
      property_360tour: [this.booking.property_360tour, us.validatorsReq()],
    });

     this.onChanges();
  }

   onChanges(): void {
  this.myForm.valueChanges.subscribe(val => {
   //console.log(val,'anuj')
   this.Changes = true;
  });
}

  onSubmit(form: FormGroup) {
    this.bookingsService.update(form.value);
     this.Changes = false;
  }

  updateBookingStatus(booking_status_id: number) {
    this.bookingsService.update({'booking_status_id': booking_status_id});
     this.Changes = false;
  }

  /* Show/Hide */

  showTitleEscrowCompany() {
    return this.bookingsService.showTitleEscrowCompany();
  }

  showNHDCompany() {
    return this.bookingsService.showNHDCompany();
  }

  showHomeWarrantyCompany() {
    return this.bookingsService.showHomeWarrantyCompany();
  }

  showHOA() {
    return this.bookingsService.showHOA();
  }

  showMellaroos() {
    return this.bookingsService.showMellaroos();
  }

  showTitleEscrowAsOne() {
    return this.bookingsService.showTitleEscrowAsOne();
  }

  /* ReadOnly */
  readOnlyNHD() {
    return this.bookingsService.readOnlyNHD();
  }

  readOnlyTitle() {
    return this.bookingsService.readOnlyTitle();
  }

  /* Change */
  changeEscrowCoName(newValue) {
    if (this.showTitleEscrowAsOne()) {
      this.myForm.get('title_co_name').setValue(newValue);
    }
  }

  changeEscrowCoRepName(newValue) {
    if (this.showTitleEscrowAsOne()) {
      this.myForm.get('title_co_rep_name').setValue(newValue);
    }
  }

  changeEscrowCoRepEmail(newValue) {
    if (this.showTitleEscrowAsOne()) {
      this.myForm.get('title_co_rep_email').setValue(newValue);
    }
  }

}

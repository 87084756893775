import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UsersService} from '../../_services/users.service';
import {AuthService} from '../../_services/auth.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  ForgotPassForm: FormGroup;
  loading = false;
  isLoading = false;
  submitted = false;
  rememberMe = false;

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private usersService: UsersService,
              private authService: AuthService,
              private toastr: ToastrService) {

  }

  ngOnInit() {

    if (typeof this.usersService.userLoggedIn !== 'undefined' && this.usersService.userLoggedIn) {
      this.router.navigate(['bookings']);
    }

    this.usersService.userLoggedIn = false;

    this.ForgotPassForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.ForgotPassForm.controls;
  }

  forgotpassword() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.ForgotPassForm.invalid) {
      this.toastr.error('Please enter your username and password.', 'User Login');
      return;
    }

    this.isLoading = true;
    const fields = this.ForgotPassForm.value;

    this.authService.forgotPassword(fields.email)
      .then((res) => {
        const user = res; // login successful if there's a jwt token in the response
        if (user) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // to keep user logged in between page refreshes
          setTimeout(() => {
            this.router.navigate(['auth/login']);
            this.toastr.success('Thank you for your account request. If an account matches the email provided, we sent a password reset link to to the email on file', 'Forgot Password');
         
          }, 1);
        }
      }).catch(
      (err) => {

        this.toastr.error('Please check your email address.', 'Could not found!');

        this.isLoading = false;

      });
  }

}

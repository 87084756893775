import {AfterViewInit, Component, ElementRef, OnInit, Output, ViewChild} from '@angular/core';
import {OpenHousesDatasource} from '../../_services/datasources/open-houses.datasource';
import {BookingsService} from '../../_services/bookings.service';
import {MatDialog, MatDialogConfig, MatSort} from '@angular/material';
import {OpenHousesService} from '../../_services/open-houses.service';
import {AddOpenHouseDialogComponent} from './add-open-house-dialog/add-open-house-dialog.component';
import {Booking} from '../../_models/booking';
import { MatConfirmDialogComponent } from '../_dialogs/mat-confirm-dialog/mat-confirm-dialog.component';

@Component({
  selector: 'app-open-houses',
  templateUrl: './open-houses.component.html',
  styleUrls: ['./open-houses.component.css']
})
export class OpenHousesComponent implements OnInit {

  allowSubmitForReview: boolean;
  selectedOpenhouse:Array<any>;

  booking: Booking;

  dataSource: OpenHousesDatasource;

  displayedColumns = ['id', 'open_house_date', 'start_time', 'end_time', 'num_of_viewings'];


  constructor(private bookingsService: BookingsService,
              private openHousesService: OpenHousesService,
              private dialog: MatDialog) {
              this.selectedOpenhouse = [];
  }

  ngOnInit() {
    
    this.dataSource = new OpenHousesDatasource(this.openHousesService);
    this.dataSource.loadData(this.bookingsService.bookingId);

    this.openHousesService.openHousesLoaded.subscribe(res => {
      this.dataSource.loadData(this.bookingsService.bookingId);
    });

    if (typeof this.bookingsService.currentBooking !== 'undefined') {
      this.booking = this.bookingsService.currentBooking;
      this.allowSubmitForReview = this.bookingsService.allowSubmitForReview();
    }

    this.bookingsService.bookingLoaded.subscribe(res => {
      this.booking = this.bookingsService.currentBooking;
      this.allowSubmitForReview = this.bookingsService.allowSubmitForReview();

    });
  }

  showSubmitForReviewButton() {
    if (typeof this.bookingsService.currentBooking !== 'undefined') {
      this.booking = this.bookingsService.currentBooking;
      return this.booking.booking_status === 'Draft';
    } else {
      return false;
    }
  }

  onAddOpenHouse() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';

    const dialogRef = this.dialog.open(AddOpenHouseDialogComponent,
      dialogConfig);


    dialogRef.afterClosed().subscribe(
      val => console.log('Dialog output:', val)
    );

  }

  onEdit(index: number) {

    console.log(this.openHousesService.getItemFromLastResult(index));

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.data = this.openHousesService.getItemFromLastResult(index);

    const dialogRef = this.dialog.open(AddOpenHouseDialogComponent,
      dialogConfig);


    dialogRef.afterClosed().subscribe(
      val => console.log('Dialog output:', val)
    );
  }

  updateBookingStatus(booking_status_id: number) {
    this.bookingsService.update({'booking_status_id': booking_status_id});
  }

  toTime(timeString) {
    const timeTokens = timeString.split(':');
    return new Date(1970, 0, 1, timeTokens[0], timeTokens[1], timeTokens[2]);
  }

  //Delete Open House 

  onDeleteOpenHouse(){
    const dialogRef = this.dialog.open(MatConfirmDialogComponent,{
    data:{ width: '350px',
      title: "Delete Open House",
        message: "Are you sure you want to delete this open house? <br/> This action can not be undone."}
    });

      dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.bookingsService.deleteOpenHouse(this.selectedOpenhouse,this.bookingsService.bookingId).then(res => {
            console.log(res.openhouses);
            this.selectedOpenhouse = [];
            this.dataSource = res.openhouses;
            this.dataSource.loadData(res.openhouses);
        // DO SOMETHING
      });
      }
    });
  }

  
  changeEvent(event,id){
    if (event.checked) {
            this.selectedOpenhouse.push(id);
        }
        else {
        var index = this.selectedOpenhouse.indexOf(id);
        if (index > -1) {
           this.selectedOpenhouse.splice(index, 1);
        }
      }
  }
 
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {Booking, BookingList, BookingCodes} from '../_models/booking';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';
import {ErrorService} from '../_services/error.service';
import {ToastrService} from 'ngx-toastr';
import {UsersService} from './users.service';

@Injectable()
export class BookingsService {

  currentBooking: Booking;  // the current booking loaded
  bookingId: number;        // the current booking's ID
  bookingLoaded = new Subject<Booking>(); // an observable to notify others a new booking has been loaded
  chipsLoaded = new Subject<boolean>();

  bookingStatusListLoaded = new Subject<BookingList[]>();
  bookingStatuslist: BookingList[];

  bookingStatesListLoaded = new Subject<BookingCodes[]>();
  bookingStateslist: BookingCodes[];

  // Information
  chip_inReq = 0;
  chip_inCnt = 0;

  // Listing
  chip_liReq = 0;
  chip_liCnt = 0;

  // Disclosures
  chip_diReq = 0;
  chip_diCnt = 0;

  // Reports
  chip_reReq = 0;
  chip_reCnt = 0;

  // Open Houses
  chip_ohReq = 2;
  chip_ohCnt = 0;

  // Tasks
  chip_taReq = 0;
  chip_taCnt = 0;

  // BDR
  chip_disCnt = 0;
  chip_repCnt = 0;

  chip_phoReq = 15;
  chip_phoCnt = 0;

  canadianProvinces = [
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NS',
    'ON',
    'PE',
    'QC',
    'SK'
  ];

  constructor(private http: HttpClient,
              private authService: AuthService,
              private router: Router,
              public errorService: ErrorService,
              private toastr: ToastrService,
              private usersService: UsersService
  ) {

  }


  findBookings(filter = '', sortOrder = 'asc',
               pageNumber = 0, pageSize = 3): Observable<Booking[]> {

    return this.http.get(`${environment.apiEndPoint}bookings`, {
      headers: this.authService.jwt(),
      params: new HttpParams()
        .set('filter', filter)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    }).pipe(
      map(res => res['data'])
    );
  }

  getBookingCount(filter = '', sortOrder = 'asc',
                  pageNumber = 0, pageSize = 3) {

    return this.findBookingAll(filter, sortOrder, pageNumber, pageSize).pipe(
      map(res => res['results'])
    );
  }

  getBookingList(selectedStatus = '', selectedStates = '', selectedAgents = '', selectedDirectors = '', selectedRegions = '', sortOrder = 'asc',
                 pageNumber = 0, pageSize = 3, usertype = '', userid = '') {

    return this.http.get(`${environment.apiEndPoint}bookings`, {
      headers: this.authService.jwt()
      ,
      params: new HttpParams()
        .set('page', pageNumber.toString())
        .set('per_page', pageSize.toString())
        .set('booking_status_id', selectedStatus)
        .set('address_state', selectedStates)
        .set('listing_agent_id', selectedAgents)
        .set('regional_director_id', selectedDirectors)
        .set('region_id', selectedRegions)
    });
  }

  findBookingAll(filter = '', sortOrder = 'asc',
                 pageNumber = 0, pageSize = 3): Observable<any> {

    return this.http.get(`${environment.apiEndPoint}bookings`, {
      headers: this.authService.jwt(),
      params: new HttpParams()
        .set('filter', filter)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    }).pipe(
      map(res => res)
    );
  }

  findBookingById(bookingId: number) {

    this.http.get(`${environment.apiEndPoint}bookings/${bookingId}`, {
      headers: this.authService.jwt()
    }).subscribe(res => {

      this.currentBooking = res['data'][0];
      this.processBooking();
      this.bookingLoaded.next(this.currentBooking);
    });
  }

  processBooking() {

    // this.chip_inCnt
    this.chip_inReq = 0;

    this.chip_ohReq = (this.isLand()) ? 0 : 2;
    this.chip_ohCnt = this.currentBooking.open_houses.length;

    this.chip_disCnt = this.currentBooking.pending_disclosures_count;
    this.chip_repCnt = this.currentBooking.pending_reports_count;

    this.chip_phoCnt = this.currentBooking.photos_count;

    this.chip_liReq = 18;

    // Seller 1 info
    this.chip_liReq -= (this.currentBooking.seller1_phone !== '') ? 1 : 0;
    this.chip_liReq -= (this.currentBooking.seller1_email !== '') ? 1 : 0;
    this.chip_liReq -= (this.currentBooking.seller1_name !== '') ? 1 : 0;

    // escrow_co_name
    this.chip_liReq = (!this.showTitleEscrowCompany()) ? this.chip_liReq - 1 : this.chip_liReq;
    // title_co_name
    this.chip_liReq = (!this.showTitleEscrowCompany()) ? this.chip_liReq - 1 : this.chip_liReq;
    // title_co_rep_name
    this.chip_liReq = (!this.showTitleEscrowCompany()) ? this.chip_liReq - 1 : this.chip_liReq;
    // title_co_rep_email
    this.chip_liReq = (!this.showTitleEscrowCompany()) ? this.chip_liReq - 1 : this.chip_liReq;
    // homewarranty_co_name
    this.chip_liReq = (!this.showHomeWarrantyCompany()) ? this.chip_liReq - 1 : this.chip_liReq;
    // homewarranty_amount
    this.chip_liReq = (!this.showHomeWarrantyCompany()) ? this.chip_liReq - 1 : this.chip_liReq;
    // melaroos
    this.chip_liReq = (!this.showMellaroos()) ? this.chip_liReq - 1 : this.chip_liReq;


    this.chip_liCnt = 0;
    this.chip_liCnt += (this.currentBooking.mls_live_date !== '0000-00-00' && this.currentBooking.mls_live_date !== '') ? 1 : 0;
    console.log(this.chip_liCnt);
    this.chip_liCnt += (this.currentBooking.bidding_reg_deadline_date !== '') ? 1 : 0;
    console.log(this.chip_liCnt);
    this.chip_liCnt += (this.currentBooking.starting_bid_price * 1 !== 0) ? 1 : 0;
    console.log(this.chip_liCnt);
    this.chip_liCnt += (this.currentBooking.deposit_amount * 1 !== 0) ? 1 : 0;
    console.log(this.chip_liCnt);
    this.chip_liCnt += (this.currentBooking.reserve_price * 1 !== 0) ? 1 : 0;
    console.log(this.chip_liCnt);
    this.chip_liCnt += (this.currentBooking.escrow_co_name !== '') ? 1 : 0;
    console.log(this.chip_liCnt);
    this.chip_liCnt += (this.currentBooking.title_co_name !== '') ? 1 : 0;
    console.log(this.chip_liCnt);
    this.chip_liCnt += (this.currentBooking.title_co_rep_name !== '') ? 1 : 0;
    console.log(this.chip_liCnt);
    this.chip_liCnt += (this.currentBooking.title_co_rep_email !== '') ? 1 : 0;
    console.log(this.chip_liCnt);
    this.chip_liCnt += (this.currentBooking.homewarranty_co_name !== '') ? 1 : 0;
    console.log(this.chip_liCnt);
    this.chip_liCnt += (this.currentBooking.homewarranty_amount !== 0) ? 1 : 0;
    console.log(this.chip_liCnt);
    this.chip_liCnt += (this.currentBooking.melaroos !== '') ? 1 : 0;
    console.log(this.chip_liCnt);
    this.chip_liCnt += (this.currentBooking.property_type !== '') ? 1 : 0;
    console.log(this.chip_liCnt);


    this.chip_liCnt += (this.currentBooking.auction_listing_agreement !== '') ? 1 : 0;

    this.chip_liCnt += (this.currentBooking.seller_email_communication_trail !== '') ? 1 : 0;

    this.chipsLoaded.next(true);
  }

  updateBooking(chip_phoCnt) {
    this.chip_phoCnt = chip_phoCnt;
    this.chipsLoaded.next(true);
  }

  allowSubmitForReview() {

    return this.currentBooking.mls_live_date !== '0000-00-00' &&
      this.currentBooking.bidding_reg_deadline_date !== '' &&
      this.currentBooking.deposit_amount * 1 !== 0 &&
      this.currentBooking.starting_bid_price * 1 !== 0 &&
      this.currentBooking.reserve_price * 1 !== 0 &&

      ((this.showTitleEscrowCompany()) ? this.currentBooking.escrow_co_name !== '' : true) &&
      ((this.showTitleEscrowCompany()) ? this.currentBooking.title_co_name !== '' : true) &&
      ((this.showTitleEscrowCompany()) ? this.currentBooking.title_co_rep_name !== '' : true) &&
      ((this.showTitleEscrowCompany()) ? this.currentBooking.title_co_rep_email !== '' : true) &&

      ((this.showHomeWarrantyCompany()) ? this.currentBooking.homewarranty_co_name !== '' : true) &&
      ((this.showHomeWarrantyCompany()) ? this.currentBooking.homewarranty_amount !== 0 : true) &&
      ((this.showMellaroos()) ? this.currentBooking.melaroos !== '' : true) &&
      this.currentBooking.property_type !== '' &&
      ((this.isLand()) ? true : this.currentBooking.open_houses.length >= 2) &&

      this.currentBooking.auction_listing_agreement !== '' &&
      this.currentBooking.seller_email_communication_trail !== ''
      ;

    /*
      this.currentBooking.seller1_name !== '' &&
      this.currentBooking.seller1_email !== '' &&
      this.currentBooking.seller1_phone !== '' &&
      this.currentBooking.mls_live_date !== '' &&
      this.currentBooking.starting_bid_price + '' !== '' &&
      this.currentBooking.deposit_amount + '' !== '' &&     // not needed for Submit state
      this.currentBooking.escrow_co_name !== '' &&
      this.currentBooking.escrow_co_rep_name !== '' &&
      this.currentBooking.escrow_co_rep_email !== '' &&
      this.currentBooking.title_co_name !== '' &&
      this.currentBooking.title_co_rep_name !== '' &&
      this.currentBooking.title_co_rep_email !== '' &&
      this.currentBooking.homewarranty_co_name !== '' &&
      this.currentBooking.homewarranty_amount + '' !== '' &&
      this.currentBooking.hoa_applicable !== '' &&
      this.currentBooking.melaroos !== '' &&
      this.currentBooking.property_type !== '' &&
      this.currentBooking.open_houses.length >= 1;        // update to 1
      */
  }

  updateOpenHouse(open_houses) {

    this.currentBooking.open_houses.push(open_houses);
    //this.currentBooking.open_houses.push(open_houses.slice(-1)[0]);
    this.processBooking();
    this.bookingLoaded.next(this.currentBooking);
  }

  // Do we need an observable? Because we can just update activeBooking booking

  insert(fields: {}, dialogRef): Promise<any> {
    return this.http.post(`${environment.apiEndPoint}bookings`,
      fields, {
        headers: this.authService.jwt()
      }
    )
      .toPromise()
      .then(res => {
        if (res) {
          this.currentBooking = res['data'][0];
          this.router.navigate(['/bookings', +this.currentBooking.id, 'listing']);
          dialogRef.close();
          return res;
        }
      })
      .catch(res => {
        this.errorService.displayError(res);
      });
  }

  update(fields: {}, bookingId?): Promise<any> {

    bookingId = (bookingId == null) ? this.currentBooking.id : bookingId;

    return this.http.patch(`${environment.apiEndPoint}bookings/${bookingId}`,
      fields, {
        headers: this.authService.jwt()
      }
    )
      .toPromise()
      .then(res => {
        if (res) {
          this.currentBooking = res['data'][0];
          this.bookingLoaded.next(this.currentBooking);
          this.processBooking();

          this.toastr.success('Successfully updated', 'Booking');
        }
      })
      .catch(res => {
        this.errorService.displayError(res);
      });
  }

  //Delete Open Houses

  deleteOpenHouse(openHouseIds, bookingId): Promise<any> {

    return this.http.post(`${environment.apiEndPoint}deleteOpenHouse/${bookingId}`,
      openHouseIds, {
        headers: this.authService.jwt()
      }
    )
      .toPromise()
      .then(res => {
        if (res) {
          this.toastr.success('The open house was successfully deleted.', 'Success.');
          return res;
        }
      })
      .catch(res => {
        this.errorService.displayError(res);
      });

  }


  findBookingStatusList() {
    this.http.get(`${environment.apiEndPoint}bookings/bookingStatusLists`, {
      headers: this.authService.jwt()
    }).subscribe(res => {
      this.bookingStatusListLoaded.next(res['bookings']);
      this.bookingStatuslist = res['bookings'];
    });
  }

  findBookingStatesList() {
    this.http.get(`${environment.apiEndPoint}bookings/bookingStatesLists`, {
      headers: this.authService.jwt()
    }).subscribe(res => {
      this.bookingStatesListLoaded.next(res['bookings']);
      this.bookingStateslist = res['bookings'];
    });
  }

  regenerateDocuments(fields: {}, bookingId?): Promise<any> {

    bookingId = (bookingId == null) ? this.currentBooking.id : bookingId;

    return this.http.patch(`${environment.apiEndPoint}bookings/${bookingId}/documents`,
      fields, {
        headers: this.authService.jwt()
      }
    )
      .toPromise()
      .then(res => {
        if (res) {
          this.currentBooking = res['data'][0];
          this.bookingLoaded.next(this.currentBooking);
          this.processBooking();

          this.toastr.success('Successfully updated', 'Booking');

          alert('Document regeneration request has been performed. It can take some time for the new documents to appear here.');
        }
      })
      .catch(res => {
        this.errorService.displayError(res);
      });
  }


  calendars(bookingStates, bookingStatuses, eventTypes, listingAgents, offices, regionalDirectors, regions, bookingId): Observable<Booking[]> {
    return this.http.get(`${environment.apiEndPoint}calendars`, {
      headers: this.authService.jwt(),
      params: new HttpParams()
        // .set('filter[address_state]', bookingStates)
        .set('filter[booking_status_id]', bookingStatuses)
        .set('filter[event_type]', eventTypes)
        .set('filter[listing_agent_id]', listingAgents)
        .set('filter[office_id]', offices)
        .set('filter[regional_director_id]', regionalDirectors)
        .set('filter[booking_id]', bookingId)
      // .set('filter[region_id]', regions)
    }).pipe(
      map(res => {
          return res['data'];
        }
      ));
  }


  showTitleEscrowAsOne() {
    const showIfState = ['CA'];

    return !showIfState.includes(this.currentBooking.address_state);
  }

  readOnlyNHD() {
    return !this.usersService.currentUserIsAdmin();
  }

  readOnlyTitle() {

    if (this.usersService.currentUserCanEditTitle()) {
      return false;
    }
    if (this.currentBooking.title_co_read_only) {
      return true;
    }
    const showIfState = ['CA']; // CA
    return showIfState.includes(this.currentBooking.address_state);
  }

  isCanada() {
    return this.canadianProvinces.includes(this.currentBooking.address_state);
  }

  isLand() {
    return this.currentBooking.property_type === 'Land';
  }

  showTitleEscrowCompany() {

    if (this.isCanada()) {
      return false;
    }

    return true;

  }

  showNHDCompany() {

    if (this.isCanada()) {
      return false;
    }

    const showIfState = ['CA'];

    return showIfState.includes(this.currentBooking.address_state);

  }

  showHomeWarrantyCompany() {

    if (this.isCanada()) {
      return false;
    }

    return true;

  }

  showHOA() {

    if (this.isCanada()) {
      return false;
    }

    return true;

  }

  showMellaroos() {

    if (this.isCanada()) {
      return false;
    }

    const hideIfState = ['OR'];

    return !hideIfState.includes(this.currentBooking.address_state);

  }


}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingsService } from '../../_services/bookings.service';
import { Booking } from '../../_models/booking';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-booking-edit',
  templateUrl: './booking-edit.component.html',
  styleUrls: ['./booking-edit.component.css']
})
export class BookingEditComponent implements OnInit, OnDestroy {

  bookingId: number;
  booking: Booking;
  navigationSubscription;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private bookingsService: BookingsService) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        // this.ngOnInit();
      }
    });

  }

  ngOnInit() {

    console.log(`Entered booking-edit ngOnInit`);

    // On first load, get the ID of the route and load the booking
    this.bookingId = this.route.snapshot.params['id'];

    // Store the ID in the service
    this.bookingsService.bookingId = this.bookingId;

    // Fetch the booking to use later, too.
    this.bookingsService.findBookingById(this.bookingId);

    this.bookingsService.bookingLoaded.subscribe(() => {
      this.booking = this.bookingsService.currentBooking;
    });

  }

  ngOnDestroy() {
    // this.bookingsService.bookingLoaded.unsubscribe();
  }

}

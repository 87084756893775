import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, OnDestroy} from '@angular/core';
import {MatDialog, MatDialogConfig, MatPaginator, MatSort, MatSelectModule, MatSelect, MatTableDataSource} from '@angular/material';
import {Booking} from '../../_models/booking';
import {BookingsService} from '../../_services/bookings.service';
import {debounceTime, distinctUntilChanged, startWith, tap, delay, map} from 'rxjs/operators';
import {merge} from 'rxjs/observable/merge';
import {fromEvent} from 'rxjs/observable/fromEvent';
import {FormControl} from '@angular/forms';
import {BookingsDataSource} from '../../_services/datasources/bookings.datasource';
import {BookingAddDialogComponent} from '../booking-add-dialog/booking-add-dialog.component';
import {AuctionEvent} from '../../_models/auction-event';
import {AuctionEventsService} from '../../_services/auction-events.service';
import {Observable} from 'rxjs';
import {UsersService} from '../../_services/users.service';
import {ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {NgModule} from '@angular/core';
import {RegionsService} from '../../_services/regions.service';

import {SelectItem} from 'ng2-select/select/select-item';
import {Region} from '../../_models/region';
import {environment} from '../../../environments/environment';
import {ListsService} from '../../_services/lists.service';
import {User} from '../../_models/user';

export interface IAgents {
  value: number;
  label: string;
}

export interface ILists {
  value: number;
  label: string;
}

export interface ICodes {
  label: string;
  value: string;
}

export interface IRegions {
  label: string;
  value: string;
}

@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BookingListComponent implements OnInit, AfterViewInit {

  canEditStatus = false;

  userType: string;

  managerUrl: string;

  selectedAgents: string[] = [];
  selectedDirectors: string[] = [];
  selectedStatus: string[] = [];
  selectedStates: string[] = [];
  selectedRegions: string[] = [];
  selectedFilter: string = "";

  bookingStates = [];
  listingAgents = [];
  regionalDirectors = [];
  bookingStatuses = [];
  regions = [];
  pageNumber = 1;
  parPage = 20;

  dataSource: MatTableDataSource<Booking>;
  isLoading = true;

  bookList;
  paging:{};

  displayedColumns = ['id', 'address', 'status', 'auction_date', 'mls_live_date', 'bidding_start', 'regional_director_name', 'office', 'listing_agent_name'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  currentUser;

  constructor(
    private usersService: UsersService,
    private bookingsService: BookingsService,
    private dialog: MatDialog,
    private listsService: ListsService) {

    // Lists
    this.listsService.dataChanged.subscribe((data) => {
      this.bookingStates = data.booking_states.map(function (key, index) {
        return {value: key.address_state, label: key.address_state};
      });

      this.listingAgents = data.listing_agents.map(function (key, index) {
        return {value: key.id, label: key.full_name};
      });

      this.regionalDirectors = data.regional_directors.map(function (key, index) {
        return {value: key.id, label: key.full_name};
      });

      this.bookingStatuses = data.booking_statuses.map(function (key, index) {
        return {value: key.id, label: key.name};
      });

      // this.bookingStatuses = data.auction_events.map(function (key, index) {
      //   return {value: key.id, label: key.name};
      // });

      this.regions = data.regions.map(function (key, index) {
        return {value: key.id, label: key.region_name};
      });

    });
  }

  limitOptionsSelect(e) {
    const LIMIT_NUMBER = 3;
    if (e.value.length > LIMIT_NUMBER) {
      e.value.pop();
    }

  }

  ngOnInit() {

    // (<any>window).drift.on('ready', function (api, payload) {
    //
    //   // api.showWelcomeMessage({});
    //
    //   (<any>window).drift.api.startInteraction({interactionId: 91302, goToConversation: false});
    //
    //
    // });

    this.managerUrl = environment.managerUrl;

    const userType = this.usersService.currentUserType();
    const userid = this.usersService.currentUserId();

    this.userType = userType;
    if (userType === 'Managing Admin' || userType === 'Super Admin') {
      this.selectedStatus.push('1'); // Draft
      this.selectedStatus.push('2'); // Submitted for Review
      this.selectedStatus.push('3'); // Booked
      // this.filterDataSource();
      this.canEditStatus = true;
    }




    if (userType === 'Regional Director') {
      // Regional Director
      this.selectedStatus.push('3'); // Booked
      this.selectedStatus.push('5'); // Active
      this.selectedStatus.push('6'); // Auction Day
      // this.filterDataSource();
    }
    this.loadBookingData();

  }

  GetFormattedDate(date) {
    var date = date.split(' ');
    var data = '';
    if (date) {
      data = date[0].split('-');
      var data = (data[0] + '/' + data[1] + '/' + data[2] + ' ' + date[1]);
    }
    //console.log(data)
    var todayTime = new Date(data);
//console.log(todayTime);
    return todayTime;

  }

  loadBookingData(){
    const userType = this.usersService.currentUserType();
    const userid = this.usersService.currentUserId();
    this.isLoading = true;
    this.bookingsService.getBookingList(this.selectedStatus.join(), this.selectedStates.join(), this.selectedAgents.join(), this.selectedDirectors.join(), this.selectedRegions.join(), 'desc', this.pageNumber, this.parPage, userType, userid).subscribe((data) => {

      console.log('getBookingList', data['data']);
      data['data'] = data['data'].filter(a => a.auction_date = this.GetFormattedDate(a.auction_date));
      data['data'] = data['data'].filter(a => a.mls_live_date = this.GetFormattedDate(a.mls_live_date));
      //console.log('anuj1',data['data']);
      this.isLoading = false;
      this.bookList = data['data'];

      this.dataSource = new MatTableDataSource(data['data']);
      this.dataSource.paginator = this.paginator; // should be inside subsccribe otherwise will throw paginator undefined error
      // this.dataSource.sort = this.sort;
      this.paging = data['meta']['pagination'];

      // Managing Admin
      console.log(this.currentUser);

    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }



  filterDataSource() {
    this.pageNumber = 1;
    this.loadBookingData();
  }

  statesSearch(e) {
    this.filterDataSource();
  }

  listingAgentSearch(e) {
    this.filterDataSource();
  }

  regionalDirectorSearch(e) {
    this.filterDataSource();
  }

  statusSearch(e) {
    this.filterDataSource();
  }

  ngAfterViewInit() {


  }

  onAddBooking() {
    const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';

    const dialogRef = this.dialog.open(BookingAddDialogComponent,
      dialogConfig);


    dialogRef.afterClosed().subscribe(
      val => console.log('Dialog output:', val)
    );
  }

  onStatusChange(bookingId, $event) {
    this.bookingsService.update({booking_status_id: $event.value}, bookingId);
  }

  regionSearch(e) {
    this.filterDataSource();
  }

  changePagination(page){
    this.pageNumber +=page;
    this.loadBookingData();
  }
}

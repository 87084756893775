import {MatConfirmDialogComponent} from '../booking/_dialogs/mat-confirm-dialog/mat-confirm-dialog.component'
import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material'
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) {}

  openConfirmDialog(msg){
  		return this.dialog.open(MatConfirmDialogComponent,{
  			width: '390px',
  			panelClass: 'confirm-dialog-container',
  			disableClose: true,
  			data:{'message':msg}
  		});
  	}

  confirm(message?: string): Observable<boolean> {
    const confirmation = window.confirm(message || 'Is it OK?');
    return of(confirmation);
  };

 }

import {AfterViewInit, Component, ElementRef, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig, MatPaginator, MatSort} from '@angular/material';

import {DisclosuresReportsService} from '../../_services/disclosures-reports.service';
import {DisclosuresReportsDatasource} from '../../_services/datasources/disclosures-reports.datasource';
import {BookingsService} from '../../_services/bookings.service';
import {Booking} from '../../_models/booking';
import {EventEmitter} from '@angular/core';
import {UploadedFilesDatasource} from '../../_services/datasources/uploaded-files.datasource';
import {UploadedFilesService} from '../../_services/uploaded-files.service';
import {UploadReportDialogComponent} from './upload-report-dialog/upload-report-dialog.component';
import {AddReportDialogComponent} from './add-report-dialog/add-report-dialog.component';
import {saveAs} from 'file-saver';
import {UsersService} from '../../_services/users.service';
import {DialogService} from '../../_services/dialog.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  canEditStatus = false;
  canDelete = false;

  statuses = ['Pending Upload', 'Uploaded', 'Approved', 'Rejected', 'N/A'];

  booking: Booking;

  dataSource: DisclosuresReportsDatasource;
  dataSourceUploads: UploadedFilesDatasource;

  displayedColumns = ['id', 'name', 'status', 'is_required'];
  displayedColumnsUploads = ['id', 'created_at', 'related_bdrs', 'filename', 'customColumn1'];

  @ViewChild(MatSort) sort: MatSort;


  constructor(private bookingsService: BookingsService,
              private disclosuresReportsService: DisclosuresReportsService,
              private uploadedFilesService: UploadedFilesService,
              private usersService: UsersService,
              private dialog: MatDialog,
              private dialogService: DialogService,
              ) {
  }

  ngOnInit() {

    this.dataSource = new DisclosuresReportsDatasource(this.disclosuresReportsService);
    this.dataSource.loadData('reports', this.bookingsService.bookingId, '', 'asc', 0, 3);


    this.dataSourceUploads = new UploadedFilesDatasource(this.uploadedFilesService);
    this.dataSourceUploads.loadData('reports', this.bookingsService.bookingId, '', 'asc', 0, 3);


    this.disclosuresReportsService.bdrLoaded.subscribe(res => {

      this.dataSource = new DisclosuresReportsDatasource(this.disclosuresReportsService);
      this.dataSource.loadData('reports', this.bookingsService.bookingId, '', 'asc', 0, 3);

    });

    this.uploadedFilesService.uploadLoaded.subscribe(res => {

      this.dataSourceUploads = new UploadedFilesDatasource(this.uploadedFilesService);
      this.dataSourceUploads.loadData('reports', this.bookingsService.bookingId, '', 'asc', 0, 3);

    });



    const userType = this.usersService.currentUserType();
    if (userType === 'Managing Admin' || userType === 'Super Admin') {
      this.canEditStatus = true;
    }
    if (userType === 'Managing Admin' || userType === 'Super Admin' || userType === 'Listing Agent') {
      this.canDelete = true;
    }

  }

  onUploadDocuments() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(UploadReportDialogComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe((val) => {
        console.log('Dialog output:', val);
        this.dataSource.loadData('reports', this.bookingsService.bookingId, '', 'asc', 0, 3);
      }
    );
  }

  onAddReport() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(AddReportDialogComponent,
      dialogConfig);


    dialogRef.afterClosed().subscribe(
      val => console.log('Dialog output:', val)
    );
  }

  onEditReport(index: number) {

    console.log(this.disclosuresReportsService.getItemFromLastResult(index));

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.maxHeight = '700px';
    dialogConfig.data = this.disclosuresReportsService.getItemFromLastResult(index);

    const dialogRef = this.dialog.open(AddReportDialogComponent,
      dialogConfig);


    dialogRef.afterClosed().subscribe(
      val => console.log('Dialog output:', val)
    );
  }

  onEditUpload(index: number) {

    console.log(this.uploadedFilesService.getItemFromLastResult(index));

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.maxHeight = '600px';
    dialogConfig.data = this.uploadedFilesService.getItemFromLastResult(index);

    const dialogRef = this.dialog.open(UploadReportDialogComponent,
      dialogConfig);


    dialogRef.afterClosed().subscribe(
      val => console.log('Dialog output:', val)
    );
  }

  onStatusChange(dId, $event) {
    // console.log(`Changed ot `, dId);
    // console.log(`Changed event `, $event);
    const fields = {status: $event};
    this.disclosuresReportsService.update('report', dId, fields);

  }

  onDownloadReportFile(filepath, filename) {

    const uploadfilename = filepath.split('/').pop();
    this.uploadedFilesService.downloadReportFile(this.bookingsService.bookingId, uploadfilename)
      .subscribe(res => {
        const blob = new Blob([res], {type: 'application/octet-stream'});
        saveAs(blob, filename);
      }, err => {
        console.log(err);
      });
  }



// //Delete FIle
  onDeleteFile(id) {

     this.dialogService.openConfirmDialog('Are you sure to delete this record ?').afterClosed().subscribe(res =>{
        if(res){

           this.uploadedFilesService.deleteFile(id).subscribe(res => {
             this.dataSource.loadData('reports', this.bookingsService.bookingId, '', 'asc', 0, 3);
             this.dataSourceUploads.loadData('reports', this.bookingsService.bookingId, '', 'asc', 0, 3);
           }, err => {
             console.log(err);
          })
        }
     });
  }



  onDownloadAll() {
    this.uploadedFilesService.zipAllFiles(this.bookingsService.bookingId)
      .subscribe(res => {
        const blob = new Blob([res], {type: 'application/octet-stream'});
        saveAs(blob, this.bookingsService.bookingId + '.zip');
      }, err => {
        console.log(err);
      });
  }

}

export class QueryBuilderService {

  build(options: { includes?, filters?, sort?, pages?}) {

    const queryParams = [];

    if (options.hasOwnProperty('includes')) {
      queryParams.push(`include=${options.includes}`);
    }
    if (options.hasOwnProperty('filters')) {
      for (const key in options.filters) {
        if (options.filters.hasOwnProperty(key)) {
          // console.log(key);
          queryParams.push(`filter[${key}]=${options.filters[key]}`);
        }
      }
    }
    if (options.hasOwnProperty('sort')) {
      queryParams.push(`sort=${options.sort}`);
    }

    if (options.hasOwnProperty('pages')) {
      for (const key in options.pages) {
        if (options.pages.hasOwnProperty(key)) {
          // console.log(key);
          queryParams.push(`page[${key}]=${options.pages[key]}`);
        }
      }
      // console.log(queryParams);
    }

    return (queryParams.length) ? '?' + queryParams.join('&') : '';

  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Office } from '../_models/office';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ErrorService } from '../_services/error.service';

@Injectable()
export class OfficesService {

  officesChanged = new Subject<Office[]>();
  offices: Office[];
  currentOffice: Office;
  officeLoaded = new Subject<Office>();

  constructor(private http: HttpClient,
    private authService: AuthService,
    public errorService: ErrorService) {

  }

  findOffices(): Observable<Office[]> {
    return this.http.get(`${environment.apiEndPoint}offices`, {
      headers: this.authService.jwt()
    }).pipe(
      map(res => {
        return res['data'];
      }
      ));
  }

  findOfficeById(officeId: number) {

    this.http.get(`${environment.apiEndPoint}offices/${officeId}`, {
      headers: this.authService.jwt()
    }).subscribe(res => {
      this.currentOffice = res['data'];
      this.officeLoaded.next(this.currentOffice);
    });
  }

  update(officeId: number, fields: {}): Promise<Office> {
    return this.http
      .put(`${environment.apiEndPoint}offices/${officeId}`, fields, {
        headers: this.authService.jwt()
      })
      .toPromise()
      .then(res => {
        if (res) {
          this.officeLoaded.next(this.currentOffice);
          return res['data'];
        }
      })
      .catch(res => {
        this.errorService.displayError(res);
      });
  }

  insert(fields: {}): Promise<Office> {
    return this.http
      .post(`${environment.apiEndPoint}offices`, fields, {
        headers: this.authService.jwt()
      })
      .toPromise()
      .then(res => {
        if (res) {
          this.officeLoaded.next(this.currentOffice);
          return res['data'];
        }
      })
      .catch(res => {
        this.errorService.displayError(res);
      });
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import { Region } from '../_models/region';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class RegionsService {

  regionsChanged = new Subject<Region[]>();
  regions: Region[];

  constructor(private http: HttpClient,
              private authService: AuthService) {

  }

  findRegions(): Observable<Region[]> {
    return this.http.get(`${environment.apiEndPoint}regions`, {
      headers: this.authService.jwt()
    }).pipe(
      map(res => {
        return  res['regions'];
        }
    ));
   }
}
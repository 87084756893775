import {Component, OnInit} from '@angular/core';
import {UsersService} from '../../_services/users.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../_services/auth.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  doSignUp;


  signupForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.usersService.userLoggedIn = false;

    this.signupForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
      cell_phone: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required]
    });
  }

  submit() {
    const fields = this.signupForm.value;

    this.authService.signup(fields)
      .then((res) => {

        setTimeout(() => {
            this.toastr.success('Thank you. Your account is being verified.', 'Account Pending');
          }, 1);
      }).catch((err) => {

            this.toastr.error('Something went wrong. Either your account is pending or the form didn\'t validate', 'Uh oh!');


      });
  }

}

import {Component, OnInit} from '@angular/core';
import {BookingsService} from '../../../_services/bookings.service';
import {Booking} from '../../../_models/booking';

@Component({
  selector: 'app-booking-sidenav-right',
  templateUrl: './sidenav-right.component.html',
  styleUrls: ['./sidenav-right.component.css']
})
export class BookingSidenavRightComponent implements OnInit {

  booking: Booking;
  hasOpenHouses = false;
  hasOpenTasks = false;
  bookingLoaded = false;
  upcoming_task : any;
  val1:any;
  val2:any;
  past_task : any;
  constructor(private bookingsService: BookingsService) {

  }

  ngOnInit() {

    if (typeof this.bookingsService.currentBooking !== 'undefined') {
      this.init();
    }

    this.bookingsService.bookingLoaded.subscribe(res => {
      this.init();
    });
  }

  init() {
    this.booking = this.bookingsService.currentBooking;
  	console.log("Current  Booking", this.booking);
    if (this.booking.open_houses.length > 0) this.hasOpenHouses = true;
    if (this.booking.open_tasks.length > 0) this.hasOpenTasks = true;
    this.bookingLoaded = true;
    console.log('Open Task')
    console.log(this.booking.open_tasks);

    var startDate = new Date().toJSON().slice(0,10);
 //console.log(this.booking.open_tasks);

        this.past_task = this.booking.open_tasks.filter(a=>a.due_date < startDate );
        this.past_task.sort((val1, val2)=> { 
          let start = +new Date(val1.due_date);
          let task = +new Date(val2.due_date) - start;
          return task;

        })
        //var newArray = this.booking.open_tasks.filter(p=>p.start_date < startDate)

        this.upcoming_task = this.booking.open_tasks.filter(a=>a.start_date >= startDate);
        //var newArray = this.booking.open_tasks.filter(p=>p.start_date < startDate)
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA } from '@angular/material';
import { DisclosuresReportsService } from '../../../_services/disclosures-reports.service';
import { BookingsService } from '../../../_services/bookings.service';

@Component({
  selector: 'app-add-report-dialog',
  templateUrl: './add-report-dialog.component.html',
  styleUrls: ['./add-report-dialog.component.css']
})
export class AddReportDialogComponent implements OnInit {

  currentUser;

  editMode = false;

  addReportForm: FormGroup;

  statuses = ['Pending Upload', 'Uploaded', 'Approved', 'Rejected'];

  constructor(
    private disclosuresReportsService: DisclosuresReportsService,
    private bookingsService: BookingsService,
    private dialogRef: MatDialogRef<AddReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {

    if (localStorage.getItem('currentUser') != null) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    } else {
      this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    }


    const editMode = this.editMode = (this.data == null) ? false : true;

    this.addReportForm = new FormGroup({
      'id': new FormControl(editMode ? this.data.id : ''),
      'name': new FormControl(editMode ? this.data.name : ''),
      'status': new FormControl(editMode ? this.data.status : ''),
      'comments': new FormControl(editMode ? this.data.comments : ''),
      'is_required': new FormControl(editMode ? this.data.is_required : false)
    });

  }

  onCancel() {
    this.dialogRef.close();
  }

  onSaveReport() {
    const fields = this.addReportForm.value;

    if (this.editMode) {
      this.disclosuresReportsService.update('report', fields.id, fields).then((res) => {
        if (res !== undefined) {
          this.dialogRef.close();
        }
      });
    } else {
      // this.disclosuresReportsService.insert('report', this.bookingsService.bookingId, fields);
      this.disclosuresReportsService.insert('report', this.bookingsService.bookingId, fields).then((res) => {
        if (res !== undefined) {
          this.dialogRef.close();
        }
      }
      );
    }
  }

}

import {Component, OnInit} from '@angular/core';
import {OpenHousesDatasource} from '../../_services/datasources/open-houses.datasource';
import {BookingsService} from '../../_services/bookings.service';
import {MatDialog} from '@angular/material';
import {OpenHousesService} from '../../_services/open-houses.service';
import {ActivatedRoute} from '@angular/router';
import {Booking} from '../../_models/booking';
import {environment} from '../../../environments/environment';
import {UsersService} from '../../_services/users.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  isAdmin = false;
  managerUrl;
  bookingId: number;
  booking: Booking;
  bookingLoaded: boolean;

  showDocuments = false;


  constructor(private route: ActivatedRoute,
              private bookingsService: BookingsService,
              private usersService: UsersService) {
  }

  ngOnInit() {
    this.isAdmin = this.usersService.currentUserIsAdmin();

    this.managerUrl = environment.managerUrl;

    if (typeof this.bookingsService.currentBooking !== 'undefined') {
      this.init();
    }

    this.bookingsService.bookingLoaded.subscribe(res => {
      this.init();
    });
  }

  init() {
    this.bookingLoaded = true;
    this.booking = this.bookingsService.currentBooking;

    this.showDocuments = this.booking.documents.length > 0;

  }

  regenerateDocuments(fields) {
    this.bookingsService.regenerateDocuments(fields);
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.authService.isTokenExpired()) {

      this.authService.logout();
      return false;
    }

    if (localStorage.getItem('currentUser')) {
      return true;
     } else if (sessionStorage.getItem('currentUser')) {
        return true;
    }

    this.router.navigate(['/auth', 'register'], {queryParams: {returnUrl: state.url}}); // if not logged in
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}

import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {User, UserAgent, UserList} from '../_models/user';
import {AuthService} from './auth.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UploadedFile} from '../_models/uploaded-file';
import {Observable, Subject} from 'rxjs';
import {map, catchError } from 'rxjs/operators';
import {Booking} from '../_models/booking';
import {of} from 'rxjs/internal/observable/of';
import { QueryBuilderService } from './query-builder.service';
import { ErrorService } from '../_services/error.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  userLoggedIn = true;

  listingAgents: User[];
  listingAgentslist: UserAgent[];

  regionalDirectors: User[];
  regionalDirectorslist: UserList[];

  LAgents: User[];
  lAgentsslist: UserList[];

  listingAgentsListLoaded = new Subject<UserAgent[]>();
  regionalDirectorsListLoaded = new Subject<UserList[]>();
  lAgentsListLoaded = new Subject<UserList[]>();

  listingAgentsLoaded = new Subject<User[]>(); // an observable to notify others a new booking has been loaded
  regionalDirectorsLoaded = new Subject<User[]>();
  lAgentsLoaded = new Subject<User[]>();

  usersLoaded = new Subject<User[]>(); // an observable to notify others a new booking has been loaded
  userLoaded = new Subject<User>();
  userdetailsLoaded = new Subject<User[]>();
  secondaryUserDetailsLoaded = new Subject<User[]>();
  regionalDirectorDetailsLoaded = new Subject<User[]>();
  userdetails = new Subject<User>();

  token = new Subject<string>(); // an observable to notify others a new booking has been loaded
  user = new Subject<string>(); // an observable to notify others a new booking has been loaded
  testSubscription = new Subject<string>();

  currentUser: User;
  userDetails: User;

  constructor(private http: HttpClient,
              private authService: AuthService,
              public errorService: ErrorService) {

  }

  findBookingById(userId: number) {

    this.http.get(`${environment.apiEndPoint}users/${userId}`, {
      headers: this.authService.jwt()
    }).subscribe(res => {

      this.currentUser = res['user'];
      this.userLoaded.next(this.currentUser);
    });
  }

  // findBookingByName(name: string) {
  //   const firstname = name.split(' ')[0];
  //   const lastname = name.split(' ')[1];

  //   const data = { firstname: firstname, lastname: lastname};
  //   // const body = JSON.stringify(data);
  //   // this.http.post(`${environment.apiEndPoint}users/getbyname`,
  //   // body, {
  //   //     headers: this.authService.jwt()
  //   //   }
  //   // ).subscribe(res => {
  //   //   this.userDetails = res['user'];
  //   //   this.userdetailsLoaded.next(this.userDetails);
  //   // });
  //   this.http.get(`${environment.apiEndPoint}users/getbyname`, {
  //     params: new HttpParams()
  //       .set('firstname', firstname)
  //       .set('lastname', lastname),
  //       headers: this.authService.jwt()
  //   }).subscribe(res => {
  //     this.userdetailsLoaded.next(res['users']);
  //     this.userdetails = res['users'];
  //   });
  // }

  public getUserByName(name: any) {
    const firstname = name.split(' ')[0];
    const lastname = name.split(' ')[1];
    const datafileds = { firstname: firstname, lastname: lastname};

    this.http.post(`${environment.apiEndPoint}users/getbyname`,
      datafileds, {
        headers: this.authService.jwt()
      }
    ).subscribe(res => {
      this.userdetailsLoaded.next(res['users']);
      return res['users'];
    });
  }

  public getSecondaryUserByName(name: any) {
    const firstname = name.split(' ')[0];
    const lastname = name.split(' ')[1];
    const datafileds = { firstname: firstname, lastname: lastname};

    this.http.post(`${environment.apiEndPoint}users/getbyname`,
      datafileds, {
        headers: this.authService.jwt()
      }
    ).subscribe(res => {
      this.secondaryUserDetailsLoaded.next(res['users']);
      return res['users'];
    });
  }

  public getRegionalDirectorByName(name: any) {
    const firstname = name.split(' ')[0];
    const lastname = name.split(' ')[1];
    const datafileds = { firstname: firstname, lastname: lastname};

    this.http.post(`${environment.apiEndPoint}users/getbyname`,
      datafileds, {
        headers: this.authService.jwt()
      }
    ).subscribe(res => {
      this.regionalDirectorDetailsLoaded.next(res['users']);
      return res['users'];
    });

  }

  findListingAgentsList(options: { includes?, filters?, sort?, pages?}): Promise<any> {
    const qb = new QueryBuilderService();
    return this.http.get(`${environment.apiEndPoint}users${qb.build(options)}`, {
      headers: this.authService.jwt(),
    })
      .toPromise()
      .then(res => {
        this.listingAgentsListLoaded.next(res['data']);
        this.listingAgentslist = res['data'];
        return res['data'];
      });
  }
  findListingAgents() {
    this.http.get(`${environment.apiEndPoint}users?filter[user_type]=Listing%20Agent`, {
      headers: this.authService.jwt()
    }).subscribe(res => {
      this.listingAgentsLoaded.next(res['data']);
      this.listingAgents = res['data'];
    });
  }

  findRegionalDirectorsList(options: { includes?, filters?, sort?, pages?}): Promise<any> {
    const qb = new QueryBuilderService();
    return this.http.get(`${environment.apiEndPoint}users${qb.build(options)}`, {
      headers: this.authService.jwt(),
    })
      .toPromise()
      .then(res => {
        this.regionalDirectorsListLoaded.next(res['data']);
        this.regionalDirectorslist = res['data'];

        return res['data'];
      });
  }

  findLAgentsList() {
    this.http.get(`${environment.apiEndPoint}users/lagentsList`, {
      headers: this.authService.jwt()
    }).subscribe(res => {
      this.lAgentsListLoaded.next(res['users']);
      this.lAgentsLoaded = res['users'];
    });
  }

  findRegionalDirectors() {
    this.http.get(`${environment.apiEndPoint}users/regionalDirectors`, {
      headers: this.authService.jwt()
    }).subscribe(res => {
      this.regionalDirectorsLoaded.next(res['users']);
      this.regionalDirectors = res['users'];
    });
  }

  getRegionalDirectors(): Observable<User[]> {
    return this.http.get(`${environment.apiEndPoint}users/regionalDirectors`, {
      headers: this.authService.jwt()
    }).pipe(
      map(res => res['users'])
    );
  }

  getListingAgents(): Observable<User[]> {
    return this.http.get(`${environment.apiEndPoint}users?filter[user_type]=Listing%20Agent`, {
      headers: this.authService.jwt()
    }).pipe(
      map(res => res['users'])
    );
  }

  findUsers(type = '', sortOrder = 'asc',
            pageNumber = 0, pageSize = 3): Observable<User[]> {
    return this.http.get(`${environment.apiEndPoint}users`, {
      headers: this.authService.jwt(),
      params: new HttpParams()
        .set('type', type)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    }).pipe(
      map(res => res['users'])
    );
  }
  notificationList() {
    return this.http.get(`${environment.apiEndPoint}notifications`, {
      headers: this.authService.jwt()
    }).pipe(
      map(results => {
          return results['data'];
        }
      )
    );
  }

  searchResult(searchValue) {
    return this.http.get(`${environment.apiEndPoint}bookings`, {
      headers: this.authService.jwt(),
      params: new HttpParams()
        .set('search', searchValue)
    }).pipe(
      map(results => {
          return results['data'];
        }
      )
    );
  }
  getUsersByType(options: { includes?, filters?, sort?, pages?}): Promise<any> {
    const qb = new QueryBuilderService();
    return this.http.get(`${environment.apiEndPoint}users${qb.build(options)}`, {
      headers: this.authService.jwt(),
    })
      .toPromise()
      .then(res => {
        return res['data'];
      });
  }

  insert(fields: {}) {
    return this.http
      .post(`${environment.apiEndPoint}users`, fields, {
        headers: this.authService.jwt()
      })
      .toPromise()
      .then(res => {
        if (res) {
          this.userdetailsLoaded.next(res['users']);
          return res['data'];
        }
      })
      .catch(res => {
        this.errorService.displayError(res);
      });
  }

  update(userId: number, fields: {}): Promise<User> {
    return this.http
      .put(`${environment.apiEndPoint}users/${userId}`, fields, {
        headers: this.authService.jwt()
      })
      .toPromise()
      .then(res => {
        if (res) {
          this.userdetailsLoaded.next(res['data']);
          return res['data'];
        }
      })
      .catch(res => {
        this.errorService.displayError(res);
      });
  }

  currentUserType() {

    let currentUser;

    if (localStorage.getItem('currentUser') != null) {
      currentUser = JSON.parse(localStorage.getItem('currentUser'));
    } else {
      currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    }
    return currentUser.user.user_type;
  }

  currentUserId() {

    let currentUser;

    if (localStorage.getItem('currentUser') != null) {
      currentUser = JSON.parse(localStorage.getItem('currentUser'));
    } else {
      currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    }
    return currentUser.user.id;
  }

  currentUserIsAdmin() {

    let currentUser;

    if (localStorage.getItem('currentUser') != null) {
      currentUser = JSON.parse(localStorage.getItem('currentUser'));
    } else {
      currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    }

    return (currentUser.user.user_type === 'Super Admin' || currentUser.user.user_type === 'Managing Admin');
  }


  currentUserCanEditTitle() {

    let currentUser;

    if (localStorage.getItem('currentUser') != null) {
      currentUser = JSON.parse(localStorage.getItem('currentUser'));
    } else {
      currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    }

    return (currentUser.user.user_can_edit_title_co === 1);
  }

  getUsers(): Promise<any> {
    return this.http.get(`${environment.apiEndPoint}users`, {
      headers: this.authService.jwt(),
    })
      .toPromise()
      .then(res => {
        return res['data'];
      });
  }

  findUserById(options: { includes?, filters?, sort?, pages?}) {
    const qb = new QueryBuilderService();
    this.http.get(`${environment.apiEndPoint}users${qb.build(options)}`, {
      headers: this.authService.jwt()
    }).subscribe(res => {
      this.currentUser = res['data'];
      this.userLoaded.next(this.currentUser);
    });
  }

  loadUser(user) {
    console.log(`Loading the User`, user);

    // (<any>window).drift.identify('USER-' + user.id, {
    //   email: user.email,
    //   phone_number: user.cell_phone
    // });

    this.usersLoaded.next(user);
  }

  testMessage() {

    console.log(this.currentUser);
    return 'hello!';
  }
}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UsersService} from '../../_services/users.service';
import {AuthService} from '../../_services/auth.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  rememberMe = false;

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private usersService: UsersService,
              private authService: AuthService,
              private toastr: ToastrService) {

  }

  ngOnInit() {

    if (typeof this.usersService.userLoggedIn !== 'undefined' && this.usersService.userLoggedIn) {
      this.router.navigate(['bookings']);
    }

    this.usersService.userLoggedIn = false;

    // localStorage.removeItem('currentUser');


    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  doLogin() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.toastr.error('Please enter your username and password.', 'User Login');
      return;
    }

    this.loading = true;

    const fields = this.loginForm.value;

    this.authService.login(fields.email, fields.password)
      .then((res) => {
        const user = res; // login successful if there's a jwt token in the response
        if (user) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // to keep user logged in between page refreshes

          localStorage.setItem('currentUser', JSON.stringify(user));
          this.usersService.token = user.token;
          this.usersService.loadUser(user.user);


          setTimeout(() => {
            this.router.navigate(['bookings']);
            this.toastr.success('User Logged In Successfully', 'User Login');
          }, 1);
        }
      }).catch(
      (err) => {

        this.toastr.error('Please check your email address and password.', 'Could not authenticate!');

        this.loading = false;

      });
  }

  rememberMeChange(event) {
    if (event.checked) {
      this.rememberMe = true;
    }
  }

}

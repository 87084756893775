import {Component, OnInit , NgZone} from '@angular/core';
import {UsersService} from '../_services/users.service';
import {AuthService} from '../_services/auth.service';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {environment} from '../../environments/environment';
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [DatePipe]
})
export class HeaderComponent implements OnInit {

  debounceTimer = null;
  managerUrl;
  token: any;
  photo: any;

  todayCount = false;
  lastWeekCount = false;
  lastMonthCount = false;
  storageResult;
  notificationCount: any;
  currentDate;
  lastMonth: any;
  lastWeek;
  lastSWeek;
  lastEWeek;
  Notification: any;
  todayNotification = [];
  lastWeekNotification = [];
  lastMonthNotification = [];
  lMonth: any;
  cDate;
  searchResult: any;
  searchResultpresent: boolean;

  constructor(private router: Router,
              private usersService: UsersService,
              private authService: AuthService,
              private datePipe: DatePipe,
              private zone: NgZone
  ) {
    this.searchResult = [];


  }

  ngOnInit() {
    $(document).ready(function () {
      $('.topbar li.search').click(function (e) {
        e.stopPropagation();
      });
    });
    this.managerUrl = environment.managerUrl;

   //if(this.usersService.currentUser){

    /*if (this.authService.isTokenExpired()) {
      console.log('call in ngOnInit logout');
      this.logOut();
    } */
    if(localStorage.getItem('currentUser')){
      this.token = this.authService.getUser().token;
    }

    this.usersService.userLoaded.subscribe(() => {
      console.log('Made it here!');
      this.token = this.usersService.currentUser.token;
      this.photo = this.usersService.currentUser.photo;
      console.log(this.usersService.currentUser);
    });

    if(localStorage.getItem('currentUser')){
    this.photo = this.authService.getUser().user.photo;
    console.log(`Current user is...`, this.authService.getUser().user.photo);
    }

  }
  //this.photo = this.authService.getUser().user.photo;

  //}

  loggedIn() {
    return localStorage.getItem('currentUser');
  }

  logOut() {
    //if(this.authService.getUser().token){
      if(localStorage.getItem('currentUser')) {
        this.authService.logout();
     }else{
      return false;
    }
    //this.router.navigate(['/login']);
  }

  applySearch(evalue, timeout = 300) {

    clearTimeout(this.debounceTimer);

    this.debounceTimer = setTimeout(() => {
      if (evalue) {
        this.searchResult = [];
        this.usersService.searchResult(evalue).subscribe(res => {

          if (res.length > 0) {
            this.searchResult = res;
            this.searchResultpresent = true;
          } else {
            this.searchResultpresent = false;
          }
        });
      } else {
        this.searchResult = [];
      }
    }, timeout);
  }

  notificationClick() {
    this.todayCount = false;
    this.lastWeekCount = false;
    this.lastMonthCount = false;
    this.todayNotification = [];
    this.lastWeekNotification = [];
    this.lastMonthNotification = [];
    this.Notification = [];

    this.currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.lastWeek = this.datePipe.transform(new Date(), 'dd');
    this.lastSWeek = this.lastWeek - 7;
    this.lastEWeek = this.lastWeek - 1;
    this.lastSWeek = this.lastSWeek > 9 ? this.lastSWeek : '0' + this.lastSWeek;
    this.lastEWeek = this.lastEWeek > 9 ? this.lastEWeek : '0' + this.lastEWeek;

    this.lMonth = this.datePipe.transform(new Date(), 'MM');
    this.lastMonth = this.lMonth - 1;
    this.lastMonth = this.lastMonth > 9 ? this.lastMonth : '-0' + this.lastMonth;

    this.lastSWeek = new Date().getFullYear() + '-' + this.lMonth + '-' + this.lastSWeek;
    this.lastEWeek = new Date().getFullYear() + '-' + this.lMonth + '-' + this.lastEWeek;
    this.lastMonth = new Date().getFullYear() + '-' + this.lastMonth;

    this.usersService.notificationList().subscribe(res => {
      this.notificationCount = res.length;
      for (let i = 0; i < res.length; i++) {
        this.lMonth = this.datePipe.transform(res[i].created_at, 'yyyy-MM');
        this.cDate = this.datePipe.transform(res[i].created_at, 'yyyy-MM-dd');

        if (this.cDate === this.currentDate) {
          this.todayCount = true;
          this.todayNotification.push({
            value: i,
            label: res[i]
          });
        } else if (this.cDate >= this.lastSWeek && this.cDate <= this.lastEWeek) {
          this.lastWeekCount = true;
          this.lastWeekNotification.push({
            value: i,
            label: res[i]
          });
        } else if (this.lMonth === this.lastMonth) {
          this.lastMonthCount = true;
          this.lastMonthNotification.push({
            value: i,
            label: res[i]
          });
        }
      }

      this.Notification = {
        todayNotification: this.todayNotification,
        lastWeekNotification: this.lastWeekNotification,
        lastMonthNotification: this.lastMonthNotification
      };

    });
  }

  public getColor(readAt): string {
    return readAt == null ? 'green' : '#797979';
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {Booking} from '../_models/booking';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';
import {DisclosureReport} from '../_models/disclosure-report';
import {Task} from '../_models/task';
import { ErrorService } from '../_services/error.service';

@Injectable()
export class DisclosuresReportsService {

  bdrLoaded = new Subject<DisclosureReport[]>(); // an observable to notify others a new booking has been loaded

  private results: DisclosureReport[];

  constructor(private http: HttpClient,
              private authService: AuthService,
              private router: Router,
              public errorService: ErrorService) {

  }

  getLastResult() {
    return this.results;

  }

  getItemFromLastResult(index) {
    return this.results[index];
  }

  find(type = 'disclosures', id = 0, filter = '', sortOrder = 'asc',
       pageNumber = 0, pageSize = 3): Observable<DisclosureReport[]> {

    return this.http.get(`${environment.apiEndPoint}${type}/${id}`, {
      headers: this.authService.jwt(),
      params: new HttpParams()
        .set('filter', filter)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    }).pipe(
      map(results => {
          this.results = results[type];
          return results[type];
        }
      )
    );
  }

  update(type: string, bdrId: number, fields: {}): Promise<any> {
    return this.http.put(`${environment.apiEndPoint}${type}/${bdrId}`,
      fields, {
        headers: this.authService.jwt()
      }
    )
        .toPromise()
        .then(res => {
            if (res) {
              this.bdrLoaded.next(this.results);
               return res;
            }
        })
        .catch(res => {
          this.errorService.displayError(res);
       });
}

  insert(type: string, bookingId: number, fields: {}) {
    type = type + 's'; // Transform singular into plural for end-point
      return this.http.post(`${environment.apiEndPoint}${type}/${bookingId}`,
      fields, {
        headers: this.authService.jwt()
      }
    ).toPromise()
        .then(res => {
              this.bdrLoaded.next(this.results);
              return res;
        })
        .catch(res => {
          this.errorService.displayError(res);
       });
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationStart, ActivatedRoute, Params, Router, CanDeactivate} from '@angular/router';
//import {ComponentCanDeactivate} from '../../_module/component-can-deactivate';
import {FormControl, FormBuilder, Validators, FormGroup} from '@angular/forms';
import {Booking} from '../../_models/booking';
import {BookingsService} from '../../_services/bookings.service';
import {catchError, debounceTime, distinctUntilChanged, finalize, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {User} from '../../_models/user';
import {UsersService} from '../../_services/users.service';

import {map, startWith} from 'rxjs/operators';
import {AuctionEvent} from '../../_models/auction-event';
import {AuctionEventsService} from '../../_services/auction-events.service';
import {UtilitiesService} from '../../_services/utils/utilities.service';
import {ListsService} from '../../_services/lists.service';
import {AuthService} from '../../_services/auth.service';
import {DialogService} from '../../_services/dialog.service';

@Component({
  selector: 'app-booking-information',
  templateUrl: './booking-information.component.html',
  styleUrls: ['./booking-information.component.css']
})
export class BookingInformationComponent implements OnInit, OnDestroy {


  private routeSub:any;  // subscription to route observer

  lastBookingLoaded = -1;

  isAdmin = false;
  canEdit = false;
  Changes = false;

  myForm: FormGroup;
  booking: Booking;
  auctionEvents: AuctionEvent[];

  regionName: any;

  listing_agent_name = '';


  filteredRegionalDirectors: Observable<User[]>;
  filteredListingAgents: Observable<User[]>;

  bookingId: number;

  bookingLoaded = false;

  auctionLocation: string;
  bookingStatus: string;

  allowSubmitForReview = false;

  canDeactivate(): Observable<boolean> | boolean {

  if(this.Changes){
    return this.dialogService.confirm('Are you sure you want to leave this page? If you leave this page you will lose any unsaved changes.');
  }else{
    return true;
  }

}
  constructor(private fb: FormBuilder,
              private usersService: UsersService,
              private bookingsService: BookingsService,
              private listsService: ListsService,
              private utilitiesService: UtilitiesService,
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private dialogService: DialogService
  ) {
// this.route.params.subscribe(params => {
//   alert();
//    // this.param = params['yourParam'];
//    // this.initialiseState(); // reset and set based on new parameter this time
// });

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
//  this.router.events.subscribe((evt) => {
//     if (evt instanceof NavigationEnd) {
//        // trick the Router into believing it's last link wasn't previously loaded
//        this.router.navigated = false;
//        // if you need to scroll back to top, here is the right place
//        window.scrollTo(0, 0);
//     }
// });
  }

  ngOnInit() {

    /*
       Register to Angular navigation events to detect navigating away (so we can save changed settings for example)
    */
    // this.routeSub = this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationStart) {
    //     // save your data
    //     alert();
    //   }
    // });

    this.isAdmin = this.usersService.currentUserIsAdmin();

    const lists = this.listsService.getData();
    this.auctionEvents = lists.auction_events;


    console.log('-- at', typeof this.bookingsService.currentBooking);
    if (typeof this.bookingsService.currentBooking !== 'undefined') {


      this.canEdit = this.usersService.currentUserIsAdmin() || this.bookingsService.currentBooking.booking_status_id !== 5;

      console.log('-- in', this.bookingsService.currentBooking);
      this.initForm();
    } else {
      console.log('-- else', this.bookingsService.currentBooking);
    }


    this.bookingsService.bookingLoaded.subscribe(res => {
      this.bookingLoaded = true;
      this.canEdit = this.usersService.currentUserIsAdmin() || this.bookingsService.currentBooking.booking_status_id !== 5;

      this.initForm();

    });


    this.regionName = this.authService.getUser().user.region_name;

  }

  ngOnDestroy() {
    console.log(`Destroying booking-information ngOnInit`);
    // this.router.events.unsubscribe();
    // this.bookingsService.bookingLoaded.unsubscribe();

    /*
    (<any>window).drift.on('ready', function (api) {

      api.hideAwayMessage();
      api.hideChat();
      api.hideWelcomeMessage();
      // api.widget.hide();

    })
     */

    //this.routeSub.unsubscribe();
  }

  initForm() {

    console.log('booking-information subscription hit');

    if (typeof this.bookingsService.currentBooking !== 'undefined' && this.bookingsService.currentBooking.booking_status_id === 3 &&
      (this.lastBookingLoaded === -1 || this.lastBookingLoaded !== this.bookingsService.currentBooking.id)) {

     // (<any>window).drift.api.startInteraction({interactionId: 91164, goToConversation: true});

    }

    if (typeof this.bookingsService.currentBooking !== 'undefined' && this.bookingsService.currentBooking.booking_status_id === 5 &&
      (this.lastBookingLoaded === -1 || this.lastBookingLoaded !== this.bookingsService.currentBooking.id)) {

     // (<any>window).drift.api.startInteraction({interactionId: 91294, goToConversation: true});

    }

    this.booking = this.bookingsService.currentBooking;
    this.allowSubmitForReview = this.bookingsService.allowSubmitForReview();

    this.lastBookingLoaded = this.booking.id;

    const us = this.utilitiesService;
    this.myForm = this.fb.group({
      listing_agent_name: [this.booking.listing_agent_name, Validators.required],
      listing_agent_id: [this.booking.listing_agent_id, Validators.required],
      secondary_listing_agent_name: [this.booking.secondary_listing_agent_name, Validators.required],
      secondary_listing_agent_id: [this.booking.secondary_listing_agent_id, Validators.required],
      address_line1: [this.booking.address_line1, Validators.required],
      address_line2: [this.booking.address_line2],
      address_city: [this.booking.address_city, Validators.required],
      address_state: [this.booking.address_state, Validators.required],
      address_zip: [this.booking.address_zip, Validators.required],
      regional_director_name: [this.booking.regional_director_name, Validators.required],
      regional_director_id: [this.booking.regional_director_id, Validators.required],
      auction_location: [this.booking.auction_location],
      auction_date: [this.booking.auction_date, Validators.required],
      auction_time: [this.booking.auction_time, Validators.required],
      //auction_event: [this.booking.auction_event_id, Validators.required],
      auction_event_id: [this.booking.auction_event_id, Validators.required],
      num_registered_bidders: [this.booking.num_registered_bidders, Validators.required],
      num_offers_prior_to_auction: [this.booking.num_offers_prior_to_auction, Validators.required],
      sale_price: [this.booking.sale_price, Validators.required],
      estimated_escrow_close_date: [us.cleanDateStr(this.booking.estimated_escrow_close_date), us.validatorsReq()],
    });

    if (this.booking.booking_status !== 'After Auction' && this.booking.booking_status !== 'Sold') {
      this.myForm.get('num_registered_bidders').disable();
      this.myForm.get('num_offers_prior_to_auction').disable();
      this.myForm.get('sale_price').disable();
      this.myForm.get('estimated_escrow_close_date').disable();

    }
    //
    // this.filteredRegionalDirectors = this.myForm.get('regional_director_name').valueChanges
    //   .pipe(
    //     startWith(null),
    //     debounceTime(200),
    //     distinctUntilChanged(),
    //     switchMap(val => {
    //       return this.filterRegionalDirectors(val || '');
    //     })
    //   );
    //
    // this.filteredListingAgents = this.myForm.get('listing_agent_name').valueChanges
    //   .pipe(
    //     startWith(null),
    //     debounceTime(200),
    //     distinctUntilChanged(),
    //     switchMap(val => {
    //       return this.filterListingAgents(val || '');
    //     })
    //   );

    this.bookingStatus = this.bookingsService.currentBooking.booking_status;

    this.setupAuctionLocation(this.myForm.get('auction_location').value);

    this.myForm.get('auction_location').valueChanges.subscribe((val) => {
      this.setupAuctionLocation(val);
    });

     this.onChanges();


  }

  onChanges(): void {
  this.myForm.valueChanges.subscribe(val => {
   //console.log(val,'anuj')
   this.Changes = true;
  });
}

  setupAuctionLocation(val) {
    if (val === 'Event') {
      this.auctionLocation = 'Event';
      this.myForm.get('auction_date').disable();
      this.myForm.get('auction_time').disable();
      //this.myForm.get('auction_event').enable();
      this.myForm.get('auction_event_id').enable();
    }
    if (val === 'On site') {
      this.auctionLocation = 'On site';
      //this.myForm.get('auction_event').disable();
      this.myForm.get('auction_event_id').disable();
      this.myForm.get('auction_date').enable();
      this.myForm.get('auction_time').enable();
    }
  }

  filterRegionalDirectors(val: string): Observable<any[]> {
    return this.usersService.getRegionalDirectors()
      .pipe(
        map(response => response.filter(option => {

          if (typeof val === 'string') {
            return option.first_name.toLowerCase().indexOf(val.toLowerCase()) === 0;
          }
        }))
      );
  }

  filterListingAgents(val: string): Observable<any[]> {
    return this.usersService.getListingAgents()
      .pipe(
        map(response => response.filter(option => {
          if (typeof val === 'string') {
            return option.first_name.toLowerCase().indexOf(val.toLowerCase()) === 0;
          }
        }))
      );
  }

  displayFn(user?: User): string | undefined {

    // ToDo: Update this -- Critical
    // if (typeof user.last_name !== 'undefined') {
    //   return `${user.first_name} ${user.last_name}`;
    // }

    return user + '';


  }

  onRegionalDirectorChange($event) {
    const user = $event.option.value;
    this.myForm.get('regional_director_id').setValue(user.id);
  }

  onListingAgentChange($event) {
    const user = $event.option.value;
    this.myForm.get('listing_agent_id').setValue(user.id);
  }

  onSubmit(form: FormGroup) {
    this.bookingsService.update(form.value);
     this.Changes = false;
  }


  updateBookingStatus(booking_status_id: number) {
    this.bookingsService.update({'booking_status_id': booking_status_id});
    this.Changes = false;
  }


}

import {Component, OnInit} from '@angular/core';
import {Booking} from '../../../_models/booking';
import {BookingsService} from '../../../_services/bookings.service';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {SoldDialogComponent} from '../../_dialogs/sold-dialog/sold-dialog.component';
import {BookingAddDialogComponent} from '../../booking-add-dialog/booking-add-dialog.component';

@Component({
  selector: 'app-next-booking-statuses',
  templateUrl: './next-booking-statuses.component.html',
  styleUrls: ['./next-booking-statuses.component.css']
})
export class NextBookingStatusesComponent implements OnInit {
  booking_status_id: number;
  booking: Booking;
  bookingLoaded = false;
  userType;
  currentUser;

  constructor(private bookingsService: BookingsService,
              public dialog: MatDialog) {
  }

  ngOnInit() {


    if (localStorage.getItem('currentUser') != null) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    } else {
      this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    }
    this.userType = this.currentUser.user.user_type;

    if (typeof this.bookingsService.currentBooking !== 'undefined') {
      this.booking = this.bookingsService.currentBooking;
      this.bookingLoaded = true;
    }

    this.bookingsService.bookingLoaded.subscribe(res => {
      this.bookingLoaded = true;
      this.booking = this.bookingsService.currentBooking;
    });
  }

  updateBookingStatus(booking_status_id: number) {

    this.booking_status_id = booking_status_id;

    this.bookingsService.update({'booking_status_id': booking_status_id});

    // Sold or After Auction or In Escrow
    if (booking_status_id === 12 || booking_status_id === 7 || booking_status_id === 10) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.autoFocus = true;
      dialogConfig.width = '350px';

      const dialogRef = this.dialog.open(SoldDialogComponent,
        dialogConfig);

      dialogRef.afterClosed().subscribe(
        // val => console.log('Dialog output:', val)
      );
    }
  }

  openDialog(): void {

    const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '250px';

    const dialogRef = this.dialog.open(SoldDialogComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(
      val => console.log('Dialog output:', val)
    );

  }
}

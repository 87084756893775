import {Component, OnInit} from '@angular/core';
import {UtilitiesService} from '../../_services/utils/utilities.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BookingsService} from '../../_services/bookings.service';
import {Booking} from '../../_models/booking';

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss']
})
export class MeetingsComponent implements OnInit {


  myForm: FormGroup;

  booking: Booking;
  bookingLoaded;
  bookingId: number;

  allowSubmitForReview: boolean;

  constructor(private fb: FormBuilder,
              private bookingsService: BookingsService,
              private utilitiesService: UtilitiesService
  ) {
  }

  ngOnInit() {

    if (typeof this.bookingsService.currentBooking !== 'undefined') {
      this.booking = this.bookingsService.currentBooking;
      this.allowSubmitForReview = this.bookingsService.allowSubmitForReview();
      this.initForm();
    }

    this.bookingsService.bookingLoaded.subscribe(res => {
      this.bookingLoaded = true;
      this.booking = this.bookingsService.currentBooking;
      this.allowSubmitForReview = this.bookingsService.allowSubmitForReview();
      this.initForm();

    });


  }


  initForm() {
    const us = this.utilitiesService;
    this.myForm = this.fb.group({
      seller1_name: [this.booking.seller1_name, us.validatorsAlphaReq()],
      seller1_email: [this.booking.seller1_email, us.validatorsEmailReq()],
      seller1_phone: [this.booking.seller1_phone, us.validatorsPhoneReq()],
      seller2_name: [this.booking.seller2_name, us.validatorsAlpha()],
      seller2_email: [this.booking.seller2_email, us.validatorsEmail()],
      seller2_phone: [this.booking.seller2_phone, us.validatorsPhone()],
      mls_live_date: [us.cleanDateStr(this.booking.mls_live_date), us.validatorsReq()],
      starting_bid_price: [us.floatOrEmpty(this.booking.starting_bid_price), us.validatorsAmountReq()],
      deposit_amount: [us.floatOrEmpty(this.booking.deposit_amount), us.validatorsAmountReq()],
      escrow_co_name: [this.booking.escrow_co_name, us.validatorsAlphaReq()],
      escrow_co_rep_name: [this.booking.escrow_co_rep_name, us.validatorsAlphaReq()],
      escrow_co_rep_email: [this.booking.escrow_co_rep_email, us.validatorsEmailReq()],
      title_co_name: [this.booking.title_co_name, us.validatorsAlphaReq()],
      title_co_rep_name: [this.booking.title_co_rep_name, us.validatorsAlphaReq()],
      title_co_rep_email: [this.booking.title_co_rep_email, us.validatorsEmailReq()],
      nhd_co_name: [this.booking.nhd_co_name, us.validatorsAlphaReq()],
      nhd_rep_email: [this.booking.nhd_rep_email, us.validatorsEmailReq()],
      homewarranty_co_name: [this.booking.homewarranty_co_name, us.validatorsAlphaReq()],
      homewarranty_amount: [this.booking.homewarranty_amount, us.validatorsAmountReq()],
      hoa_applicable: [this.booking.hoa_applicable, us.validatorsAlphaReq()],
      melaroos: [this.booking.melaroos, us.validatorsAlphaReq()],
      property_type: [this.booking.property_type, us.validatorsAlphaReq()],

      midcampaign_mtg_date: [us.cleanDateStr(this.booking.midcampaign_mtg_date), us.validatorsReq()],
      weekly_agentseller_mtg_date: [us.cleanDateStr(this.booking.weekly_agentseller_mtg_date), us.validatorsReq()],
      regdirseller_call1_date: [us.cleanDateStr(this.booking.regdirseller_call1_date), us.validatorsReq()],
      regdirseller_call2_date: [us.cleanDateStr(this.booking.regdirseller_call2_date), us.validatorsReq()],
      reserveset_date: [us.cleanDateStr(this.booking.reserveset_date), us.validatorsReq()],
      reserve_price: [us.floatOrEmpty(this.booking.reserve_price), us.validatorsAmountReq()],

      midcampaign_mtg_time: [this.booking.midcampaign_mtg_time, us.validatorsReq()],
      weekly_agentseller_mtg_time: [this.booking.weekly_agentseller_mtg_time, us.validatorsReq()],
      regdirseller_call1_time: [this.booking.regdirseller_call1_time, us.validatorsReq()],
      regdirseller_call2_time: [this.booking.regdirseller_call2_time, us.validatorsReq()],
      reserveset_time: [this.booking.reserveset_time, us.validatorsReq()],

      occupancy: [this.booking.occupancy, us.validatorsReq()],
      auction_listing_agreement: [this.booking.auction_listing_agreement, us.validatorsReq()],
      auction_team_to_email_campaign_to_seller: [this.booking.auction_team_to_email_campaign_to_seller, us.validatorsReq()],
      seller_email_communication_trail: [this.booking.seller_email_communication_trail, us.validatorsReq()],
      comments: [this.booking.comments, us.validatorsReq()]
    });
  }

  onSubmit(form: FormGroup) {
    this.bookingsService.update(form.value);
  }

  updateBookingStatus(booking_status_id: number) {
    this.bookingsService.update({'booking_status_id': booking_status_id});
  }

}

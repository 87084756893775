import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {DisclosureReport} from '../../../_models/disclosure-report';
import {DisclosuresReportsService} from '../../../_services/disclosures-reports.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {BookingsService} from '../../../_services/bookings.service';
import {UploadedFilesService} from '../../../_services/uploaded-files.service';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-upload-report-dialog',
  templateUrl: './upload-report-dialog.component.html',
  styleUrls: ['./upload-report-dialog.component.css']
})
export class UploadReportDialogComponent implements OnInit {

  saveBtnDisabled;

  bookingId;
  userToken;
  reportId;
  reportFile;
  reportStorePath;

  uploadFilesForm: FormGroup;

  @ViewChild('myPond') myPond: any;

  reports: DisclosureReport[];

  pondOptions = {};

  pondFiles = [
    // 'index.html'
  ];

  files_pending_upload = 0;

  constructor(
    private dialogRef: MatDialogRef<UploadReportDialogComponent>,
    private disclosuresReportsService: DisclosuresReportsService,
    private uploadedFilesService: UploadedFilesService,
    private bookingsService: BookingsService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.bookingId = this.bookingsService.currentBooking.id;

    if (localStorage.getItem('currentUser') != null) {
      this.userToken = JSON.parse(localStorage.getItem('currentUser')).token;
    } else {
      this.userToken = JSON.parse(sessionStorage.getItem('currentUser')).token;
    }


    this.pondOptions = {
      class: 'my-filepond',
      multiple: false,
      labelIdle: 'Click to upload, or drag and drop your files here',
      acceptedFileTypes: 'image/jpeg, image/png, application/pdf',
      server: {
        url: `${environment.apiEndPoint}`,
        timeout: 7000,
        process: {
          url: `reports/${this.bookingId}/uploadFile`,
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + this.userToken
          },
          withCredentials: false,
          onload: this.filepondOnUpload,
          onerror: this.filepondOnError
        },
        revert: null
      }
    };

    this.reports = this.disclosuresReportsService.getLastResult();

    const controls = this.reports.map(c => new FormControl(false));
    // controls[0].setValue(true);
    // console.log(controls);


    this.uploadFilesForm = this.formBuilder.group({
      'reports': new FormArray(controls),
      'files': new FormControl(null)
    });

    console.log('uploadFilesForm');
    console.log(this.uploadFilesForm.get('reports'));

    // this.uploadFilesForm.controls['files'].setValue('h1');
    if (this.data != null) {
      let temparray = [];
      this.reportId = this.data.id;
      this.reportFile = this.data.filename;
      this.reportStorePath = this.data.store_path;
      this.saveBtnDisabled = false;
      const tempvar = this.data.related_bdrs.replace(/<[^>]+>/g, ';');
      temparray = tempvar.split(';');
      temparray = temparray.filter(Boolean);
      console.log(temparray);
      const selectedReport = this.uploadFilesForm.value.reports;

      for (let i = 0; i < selectedReport.length; i++) {
        if (temparray.find(fruit => fruit === this.reports[i].name)) {
          controls[i].setValue(true);
        }
      }
    }

  }

  getForm (myForm) {
    return myForm.get('reports').controls;
  }

  pondHandleInit() {
    this.saveBtnDisabled = true;
  }

  pondHandleAddFile(event: any) {
    this.saveBtnDisabled = true;

    this.files_pending_upload++;
  }

  pondHandleUpload(event: any) {


    this.files_pending_upload--;

    if (this.files_pending_upload === 0) {
      this.saveBtnDisabled = false;
    }

  }

  onSubmit() {

    // Get the IDs of the selected Reports
    const selectedReportIds = this.uploadFilesForm.value.reports
      .map((v, i) => v ? this.reports[i].id : null)
      .filter(v => v !== null);

    console.log(selectedReportIds);
  if(!this.reportId){
      // Get the server Ids of the uploaded files
      const uploadedFiles = this.myPond.getFiles();
      const fileArray = [];
      for (let i = 0; i < uploadedFiles.length; i++) {
        fileArray.push(
          {
            store_path: uploadedFiles[i].serverId,
            filename: uploadedFiles[i].filename
          },
        );
      }

      const fields = {
        related: selectedReportIds,
        uploads: fileArray
      };

      this.uploadedFilesService.saveUpload('reports', this.bookingId, fields).subscribe((data) => {
        this.dialogRef.close();
      });

  }else if(this.reportId){
          const fileArray = [];
          if(this.reportStorePath){
            fileArray.push(
              {
                store_path: this.reportStorePath,
                filename: this.reportFile
              },
            );
          }
          
          const fields = {
            related: selectedReportIds,
            uploads: fileArray
          };

          this.uploadedFilesService.updateUpload('reports', this.bookingId,this.reportId, fields).subscribe((data) => {
            this.dialogRef.close();
          });

        }

  }

  filepondOnUpload(response) {
    response = JSON.parse(response);
    console.log(`onload`, response);
    console.log(`onload`, response.key);


    // this.uploadFilesForm.controls['files'].setValue('ok');

    return response.key;
  }

  filepondOnError(response) {
    console.log(`onerror`, response);
    return response.data;
  }

  isSaveBtnDisabled() {
    return this.saveBtnDisabled;
  }

}

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorService {
    displayerrors: any;

    constructor(private toastr: ToastrService) {
    }

    public handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    public displayError(res: any) {
        if (res.error.error) {
            this.displayerrors = res.error;
            // console.log(res.error.error.password.length);
            if (res.error.error.password) {
                res.error.error.password.forEach(element => {
                    this.toastr.error(element, 'Error Code - ' + res.error.code);
                });
            } else if (res.error.error.email) {
                res.error.error.email.forEach(element => {
                    this.toastr.error(element, 'Error Code - ' + res.error.code);
                });
            } else {
                const isNested = Object.keys(res.error.error).some(function (key) {
                    return res.error.error[key] && typeof res.error.error[key] === 'object';
                });
                if (isNested) {
                    for (const key in res.error.error) {
                        if (res.error.error[key]) {
                            this.toastr.error(res.error.error[key], 'Error Code - ' + res.error.code);
                        }
                    }
                } else {
                    this.toastr.error(res.error.error, 'Error Code - ' + res.error.code);
                }

            }
        } else if (res.error.message) {
            if (res.error.code !== 422) {
                this.toastr.error('Error Code - An error occured, please contact system adminsitrator ');
            } else {
                this.toastr.error(res.error.message, 'Error');
            }
        }
    }
}

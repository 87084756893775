import {Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit} from '@angular/core';
import {startOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours} from 'date-fns';
import {Subject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView} from 'angular-calendar';
import {BookingsService} from '../../_services/bookings.service';
import {Booking} from '../../_models/booking';
import {AuctionEventsService} from '../../_services/auction-events.service';
import {Title} from '@angular/platform-browser';
import {TasksService} from '../../_services/tasks.service';
import {ListsService} from '../../_services/lists.service';
import {environment} from '../../../environments/environment';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};

export interface IEventType {
  value: string;
  label: string;
}

export interface ITasks {
  value: number;
  label: string;
}

@Component({
  selector: 'app-calendar-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./calendar.component.css'],
  templateUrl: './calendar.component.html'
})
export class CalendarComponent implements OnInit {
  @ViewChild('modalContent')
  modalContent: TemplateRef<any>;

  managerUrl;

  booking: Booking;

  lists = {listing_agents: [], regional_directors: [], booking_statuses: [], booking_states: [], regions: [], auction_events: []};

  selectedBookingStates: string[] = [];
  selectedBookingStatuses: string[] = [];
  selectedEventTypes: string[] = [];
  selectedListingAgents: string[] = [];
  selectedOffices: string[] = [];
  selectedRegionalDirectors: string[] = [];
  selectedRegions: string[] = [];
  selectedBookings: string[] = [];


  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  bookingStates = [];
  bookingStatuses = [];
  eventTypes = [];
  listingAgents = [];
  offices = [];
  regionalDirectors = [];
  regions = [];

  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen = false;

  constructor(private bookingsService: BookingsService,
              private listsService: ListsService) {

  }

  ngOnInit() {

    this.listsService.getLists();

    if (typeof this.bookingsService.currentBooking !== 'undefined') {
      this.initCalendar();

    }
    this.bookingsService.bookingLoaded.subscribe(res => {
      this.initCalendar();
    });

    // Lists
    this.listsService.dataChanged.subscribe((data) => {
      this.initList();
    });

    this.managerUrl = environment.managerUrl;
  }

  initCalendar() {

    console.log(`Im in here now`);
    this.booking = this.bookingsService.currentBooking;

    this.fetchEvents('', '', '', '', '', '', '', this.booking.id);
  }

  initList() {

    const data = this.listsService.data;

    this.bookingStates = data.booking_states.map(function (key, index) {
      return {value: key.address_state, label: key.address_state};
    });

    this.listingAgents = data.listing_agents.map(function (key, index) {
      return {value: key.id, label: key.full_name};
    });

    this.regionalDirectors = data.regional_directors.map(function (key, index) {
      return {value: key.id, label: key.full_name};
    });

    this.bookingStatuses = data.booking_statuses.map(function (key, index) {
      return {value: key.id, label: key.name};
    });

    this.regions = data.regions.map(function (key, index) {
      return {value: key.id, label: key.region_name};
    });
  }


  fetchEvents(bookingStates, bookingStatuses, eventTypes, listingAgents, offices, regionalDirectors, regions, bookingId): void {
    this.bookingsService.calendars(bookingStates, bookingStatuses, eventTypes, listingAgents, offices, regionalDirectors, regions, this.booking.id).subscribe(results => {
      if (results) {

        this.events = [];

        for (let i = 0; i < results.length; i++) {

          const event = results[i];
          let color = '#ad2121'; // dark red

          if (event.event_type === 'auction_date') {
            color = '#20BF55';
          } // green
          if (event.event_type === 'midcampaign_mtg_date') {
            color = '#8789C0';
          } // purple
          if (event.event_type === 'weekly_agentseller_mtg_date') {
            color = '#EBEBD3';
          } // cream
          if (event.event_type === 'regdirseller_call1_date') {
            color = '#F4D35E';
          } // yellow
          if (event.event_type === 'regdirseller_call2_date') {
            color = '#F4D35E';
          } // yellow
          if (event.event_type === 'bidding_reg_deadline_date') {
            color = '#EE964B';
          }  // yellow
          if (event.event_type === 'reserveset_date') {
            color = '#EE964B';
          } // orange
          if (event.event_type === 'mls_live_date') {
            color = '#E84855';
          }  // salmon
          if (event.event_type === 'estimated_escrow_close_date') {
            color = '#E84855';
          }  // salmon
          if (event.event_type === 'task') {
            color = '#F9B9F2';
          } // pink
          if (event.event_type === 'open_house') {
            color = '#01BAEF';
          } // blue

          this.events.push(
            {
              start: addHours(new Date(event.date), 12),
              title: event.name,
              color: {primary: color, secondary: '#000000'}
            }
          );
          this.refresh.next();
        }
      }
    });
  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }


  filterSelect() {

    const bookingStates = this.selectedBookingStates.toString();
    const bookingStatuses = this.selectedBookingStatuses.toString();
    const eventTypes = this.selectedEventTypes.toString();
    const listingAgents = this.selectedListingAgents.toString();
    const offices = this.selectedOffices.toString();
    const regionalDirectors = this.selectedRegionalDirectors.toString();
    const regions = this.selectedRegions.toString();
    this.events = [];
    this.fetchEvents(bookingStates, bookingStatuses, eventTypes, listingAgents, offices, regionalDirectors, regions, this.booking.id);
  }
}

import {Component, OnInit} from '@angular/core';
import {BookingsService} from '../../../_services/bookings.service';

@Component({
  selector: 'app-booking-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class BookingSidenavComponent implements OnInit {

  showAdvBookingNav = false;
  isLand = false;

  // Information
  chip_inReq = 0;
  chip_inCnt = 0;

  // Listing
  chip_liReq = 0;
  chip_liCnt = 0;

  // Disclosures
  chip_diReq = 0;
  chip_diCnt = 0;

  // Reports
  chip_reReq = 0;
  chip_reCnt = 0;

  // Open Houses
  chip_ohReq = 0;
  chip_ohCnt = 0;

  // Tasks
  chip_taReq = 0;
  chip_taCnt = 0;

  //
  chip_repCnt = 0;
  chip_disCnt = 0;

  // Photos

  chip_phoCnt = 0;
  chip_phoReq = 0;


  constructor(private bookingsService: BookingsService) {
  }

  ngOnInit() {

    this.bookingsService.bookingLoaded.subscribe(() => {
      if (this.bookingsService.currentBooking.booking_status !== 'Draft') {
        this.showAdvBookingNav = true;
      }
      this.isLand = this.bookingsService.isLand();
    });

    this.bookingsService.chipsLoaded.subscribe(() => {

      this.chip_inReq = this.bookingsService.chip_inReq;
      this.chip_inCnt = this.bookingsService.chip_inCnt;
      this.chip_liReq = this.bookingsService.chip_liReq;
      this.chip_liCnt = this.bookingsService.chip_liCnt;
      this.chip_ohReq = this.bookingsService.chip_ohReq;
      this.chip_ohCnt = this.bookingsService.chip_ohCnt;
      this.chip_repCnt = this.bookingsService.chip_repCnt;
      this.chip_disCnt = this.bookingsService.chip_disCnt;
      this.chip_phoCnt = this.bookingsService.chip_phoCnt;
      this.chip_phoReq = this.bookingsService.chip_phoReq;

    });
  }


}

import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, of} from 'rxjs';

import {BehaviorSubject} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {DisclosureReport} from '../../_models/disclosure-report';
import {DisclosuresReportsService} from '../disclosures-reports.service';

export class DisclosuresReportsDatasource implements DataSource<DisclosureReport> {
  private dataSubject = new BehaviorSubject<DisclosureReport[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private myService: DisclosuresReportsService) {

  }

  loadData(
    type: string,
    id: number,
    filter: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number) {

    this.loadingSubject.next(true);

    this.myService.find(type, id, filter, sortDirection,
      pageIndex, pageSize).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(data => {
        this.dataSubject.next(data);
      });

  }

  connect(collectionViewer: CollectionViewer): Observable<DisclosureReport[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

}


import {AfterViewInit, Component, ElementRef, OnInit, Output, ViewChild} from '@angular/core';
import {OpenHousesDatasource} from '../../_services/datasources/open-houses.datasource';
import {BookingsService} from '../../_services/bookings.service';
import {MatDialog, MatDialogConfig, MatSort} from '@angular/material';
import {OpenHousesService} from '../../_services/open-houses.service';
import {AddTaskDialogComponent} from './add-task-dialog/add-task-dialog.component';
import {TasksService} from '../../_services/tasks.service';
import {TasksDatasource} from '../../_services/datasources/tasks.datasource';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent implements OnInit {

  // this.dataSource.loadData(undefined, bookingStatuses, assignedToNames, offices, regions, taskStatus);

  bookings = [];
  bookingStatuses = [];
  assignedToNames = [];
  offices = [];
  regions = [];
  taskStatuses = [];

  selectedBookingStatuses: string[] = [];
  selectedAssignedToNames: string[] = [];
  selectedOffices: string[] = [];
  selectedRegions: string[] = [];
  selectedTaskStatuses: string[] = [];
  selectedBookings: string[] = [];

  inGlobalTasks = false;

  xtraclass = '';

  dataSource: TasksDatasource;

  displayedColumns = ['id', 'assigned_to_name', 'address', 'title', 'due_date', 'is_completed'];


  constructor(private bookingsService: BookingsService,
              private tasksService: TasksService,
              private dialog: MatDialog,
              private route: ActivatedRoute) {
  }

  ngOnInit() {

    this.inGlobalTasks = (this.route.snapshot['_routerState'].url === '/tasks');

    if (this.inGlobalTasks) {
      this.xtraclass = 'col6';
      this.displayedColumns = ['id', 'title', 'assigned_to_name', 'address', 'due_date', 'is_completed'];
    } else {
      this.displayedColumns = ['id', 'title', 'assigned_to_name', 'due_date', 'is_completed'];
    }

    const params = (this.inGlobalTasks) ? {} : {'booking_id': this.bookingsService.bookingId};

    this.dataSource = new TasksDatasource(this.tasksService);

    this.dataSource.loadData(params);

    this.tasksService.tasksLoaded.subscribe(res => {
      this.dataSource.loadData(params);
    });

    // Populate dropdowns
    let currentUser;

    if (localStorage.getItem('currentUser') != null) {
      currentUser = JSON.parse(localStorage.getItem('currentUser'));
    } else {
      currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    }

    const lists = currentUser.lists;

    this.populateDropdown('bookingStatuses', lists.booking_statuses);
    this.populateDropdown('assignedToNames', lists.listing_agents);
    this.populateDropdown('offices', lists.offices);
    this.populateDropdown('regions', lists.regions);
    this.populateDropdown('bookings', lists.bookings);

    this.populateTaskStatuses();


  }

  onComplete(taskId: number, is_completed: boolean) {

    this.tasksService.update(taskId, {is_completed: is_completed});

  }

  onAddTask() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';

    const dialogRef = this.dialog.open(AddTaskDialogComponent,
      dialogConfig);


    dialogRef.afterClosed().subscribe(
      val => console.log('Dialog output:', val)
    );

  }

  onEdit(index: number) {
    console.log(`---------`, index);
    console.log(this.tasksService.getItemFromLastResult(index));

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.data = this.tasksService.getItemFromLastResult(index);

    console.log(dialogConfig.data);

    const dialogRef = this.dialog.open(AddTaskDialogComponent,
      dialogConfig);


    dialogRef.afterClosed().subscribe(
      val => console.log('Dialog output:', val)
    );
  }


  filterSelect() {


    const bookingStatuses = this.selectedBookingStatuses.toString();
    const assignedToNames = this.selectedAssignedToNames.toString();
    const offices = this.selectedOffices.toString();
    const regions = this.selectedRegions.toString();
    const taskStatus = this.selectedTaskStatuses.toString();
    const bookings = this.selectedBookings.toString();

    const params = {
      'booking_id': bookings,
      'booking_status_id': bookingStatuses,
      'office_id': offices,
      'region_id': regions,
      'assigned_to_user_id': assignedToNames,
      'is_completed': taskStatus
    };

    this.dataSource.loadData(params);

  }

  populateDropdown(key, list) {

    for (let i = 0; i < list.length; i++) {
      this[key].push({
        value: list[i].id,
        label: list[i].name
      });
    }
  }

  populateTaskStatuses() {
    this.taskStatuses.push({ value: 0, label: 'Pending'});
    this.taskStatuses.push({ value: 1, label: 'Completed'});

  }

}

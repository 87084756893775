import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';

import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';

import {DisclosureReport} from '../_models/disclosure-report';
import {UploadedFile} from '../_models/uploaded-file';
import {Task} from '../_models/task';


@Injectable()
export class UploadedFilesService {
  uploadLoaded = new Subject<UploadedFile[]>(); // an observable to notify others a new booking has been loaded

  results: UploadedFile[];

  constructor(private http: HttpClient,
              private authService: AuthService) {

  }

  find(type = 'disclosures', id = 0, filter = '', sortOrder = 'asc',
       pageNumber = 0, pageSize = 3): Observable<UploadedFile[]> {

    return this.http.get(`${environment.apiEndPoint}${type}/${id}/uploads`, {
      headers: this.authService.jwt(),
      params: new HttpParams()
        .set('filter', filter)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    }).pipe(
      map(results => {
          this.results = results['uploads'];
         //
          console.log(results);
          return results['uploads'];
        }
      )
    );
  }

  getLastResult() {
    return this.results;

  }

  getItemFromLastResult(index) {
    console.log(this.results);
    return this.results[index];
  }

  saveUpload(type = 'disclosures', id = 0, fields): Observable<UploadedFile[]> {

    console.log('saveUpload', fields);
    return this.http.post(`${environment.apiEndPoint}${type}/${id}/saveUpload`, fields, {
      headers: this.authService.jwt()
    }).pipe(
      map((res) => {

        // Fire off observable
        this.uploadLoaded.next(res['uploads']);

        return res['status'];
      })
    );
  }

 updateUpload(type = 'disclosures', id = 0,disclosureId, fields): Observable<UploadedFile[]> {

    console.log('updateUpload', fields);
    return this.http.post(`${environment.apiEndPoint}${type}/${id}/updateUpload/${disclosureId}`, fields, {
      headers: this.authService.jwt()
    }).pipe(
      map((res) => {

        // Fire off observable
        this.uploadLoaded.next(res['uploads']);

        return res['status'];
      })
    );
  }

  zipAllFiles(bookingId) {

    let fields ={'corsvalue':1};
    return this.http.post(`${environment.apiEndPoint}reports/zipAllReportFiles/${bookingId}`,
    fields, {
        headers: this.authService.jwt(),
        responseType:'arraybuffer'
      }
      );  
  }

  zipAllDiscFiles(bookingId) {

  let fields ={'corsvalue':1};
   return this.http.post(`${environment.apiEndPoint}disclosures/zipAllDiscFiles/${bookingId}`,
   fields,{
     headers: this.authService.jwt(),
     responseType:'arraybuffer'
   }
   );

 }

  downloadReportFile(bookingId, filename) {

      let fields ={'filename':filename};
      return this.http.post(`${environment.apiEndPoint}reports/downloadReportDiscFile/${bookingId}`,
      fields,{
        headers: this.authService.jwt(),
        responseType:'arraybuffer'
      }
      );
  }

  downloadDiscFile(bookingId, filename) {

      let fields ={'filename':filename};
      return this.http.post(`${environment.apiEndPoint}disclosures/downloadClDiscFile/${bookingId}`,
      fields,{
        headers: this.authService.jwt(),
        responseType:'arraybuffer'
      }
      );
  }

  deleteFile(FileID) {
//alert(FileID)
    let fields ={'id':FileID};
      return this.http.delete(`${environment.apiEndPoint}uploaded-files/${FileID}`,
       {
        headers: this.authService.jwt()
       }
      );
  }

}

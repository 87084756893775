import {AfterViewInit, Component, ElementRef, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig, MatPaginator, MatSort} from '@angular/material';

import {DisclosuresReportsService} from '../../_services/disclosures-reports.service';
import {DisclosuresReportsDatasource} from '../../_services/datasources/disclosures-reports.datasource';
import {BookingsService} from '../../_services/bookings.service';
import {Booking} from '../../_models/booking';
import {EventEmitter} from '@angular/core';
import {UploadedFilesDatasource} from '../../_services/datasources/uploaded-files.datasource';
import {UploadedFilesService} from '../../_services/uploaded-files.service';
import {UploadDisclosureDialogComponent} from './upload-disclosure-dialog/upload-disclosure-dialog.component';
import {AddDisclosureDialogComponent} from './add-disclosure-dialog/add-disclosure-dialog.component';
import {saveAs} from 'file-saver';
import {UsersService} from '../../_services/users.service';
import {DialogService} from '../../_services/dialog.service';


@Component({
  selector: 'app-disclosures',
  templateUrl: './disclosures.component.html',
  styleUrls: ['./disclosures.component.css']
})
export class DisclosuresComponent implements OnInit {

  canEditStatus = false;
  canDelete = false;
  statuses = ['Pending Upload', 'Uploaded', 'Approved', 'Rejected', 'N/A'];

  booking: Booking;

  dataSource: DisclosuresReportsDatasource;
  dataSourceUploads: UploadedFilesDatasource;

  displayedColumns = ['id', 'name', 'status', 'is_required'];
  displayedColumnsUploads = ['id', 'created_at', 'related_bdrs', 'filename', 'customColumn1'];

  @ViewChild(MatSort) sort: MatSort;


  constructor(private bookingsService: BookingsService,
              private disclosuresReportsService: DisclosuresReportsService,
              private uploadedFilesService: UploadedFilesService,
              private usersService: UsersService,
              private dialog: MatDialog,
              private dialogService: DialogService,
              ) {
  }

  ngOnInit() {

    this.dataSource = new DisclosuresReportsDatasource(this.disclosuresReportsService);
    this.dataSource.loadData('disclosures', this.bookingsService.bookingId, '', 'asc', 0, 3);


    this.dataSourceUploads = new UploadedFilesDatasource(this.uploadedFilesService);
    this.dataSourceUploads.loadData('disclosures', this.bookingsService.bookingId, '', 'asc', 0, 3);


    this.disclosuresReportsService.bdrLoaded.subscribe(res => {

      this.dataSource = new DisclosuresReportsDatasource(this.disclosuresReportsService);
      this.dataSource.loadData('disclosures', this.bookingsService.bookingId, '', 'asc', 0, 3);

    });

    this.uploadedFilesService.uploadLoaded.subscribe(res => {

      this.dataSourceUploads = new UploadedFilesDatasource(this.uploadedFilesService);
      this.dataSourceUploads.loadData('disclosures', this.bookingsService.bookingId, '', 'asc', 0, 3);

    });

    const userType = this.usersService.currentUserType();
    if (userType === 'Managing Admin' || userType === 'Super Admin') {
      this.canEditStatus = true;
    }
    if (userType === 'Managing Admin' || userType === 'Super Admin' || userType === 'Listing Agent') {
      this.canDelete = true;
    }

  }

  onUploadDocuments() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(UploadDisclosureDialogComponent,
      dialogConfig);


    dialogRef.afterClosed().subscribe((val) => {
        console.log('Dialog output:', val);
        this.dataSource.loadData('disclosures', this.bookingsService.bookingId, '', 'asc', 0, 3);
      }
    );
  }

  onAddDisclosure() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(AddDisclosureDialogComponent,
      dialogConfig);


    dialogRef.afterClosed().subscribe(
      val => console.log('Dialog output:', val)
    );
  }

  onEditDisclosure(index: number) {

    console.log(this.disclosuresReportsService.getItemFromLastResult(index));

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.maxHeight = '700px';
    dialogConfig.data = this.disclosuresReportsService.getItemFromLastResult(index);

    const dialogRef = this.dialog.open(AddDisclosureDialogComponent,
      dialogConfig);


    dialogRef.afterClosed().subscribe(
      val => console.log('Dialog output:', val)
    );
  }

  onEditUpload(index: number) {

    console.log(this.uploadedFilesService.getItemFromLastResult(index));

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.data = this.uploadedFilesService.getItemFromLastResult(index);
    //console.log('listing design',dialogConfig.data);
    const dialogRef = this.dialog.open(UploadDisclosureDialogComponent,
      dialogConfig);


    dialogRef.afterClosed().subscribe(
      val => console.log('Dialog output:', val)
    );
  }

  onStatusChange(dId, $event) {
    // console.log(`Changed ot `, dId);
    // console.log(`Changed event `, $event);
    const fields = {status: $event};
    this.disclosuresReportsService.update('disclosure', dId, fields);

  }

  onDownloadDiscFile(filepath, filename) {

    const uploadfilename = filepath.split('/').pop();
    this.uploadedFilesService.downloadDiscFile(this.bookingsService.bookingId, uploadfilename)
      .subscribe(res => {
        const blob = new Blob([res], {type: 'application/octet-stream'});
        saveAs(blob, filename);
      }, err => {
        console.log(err);
      });
  }

// //Delete FIle
  onDeleteFile(id) {


      this.dialogService.openConfirmDialog('Are you sure to delete this record ?').afterClosed().subscribe(res =>{
        if(res){
          this.uploadedFilesService.deleteFile(id).subscribe(res => {
          this.dataSource.loadData('disclosures', this.bookingsService.bookingId, '', 'asc', 0, 3);
          this.dataSourceUploads.loadData('disclosures', this.bookingsService.bookingId, '', 'asc', 0, 3);
            }, err => {
              console.log(err);
            });
        }
     });
  }


  onDownloadAll() {
    // console.log(this.bookingsService.bookingId);

    this.uploadedFilesService.zipAllDiscFiles(this.bookingsService.bookingId)
      .subscribe(res => {
        const blob = new Blob([res], {type: 'arraybuffer'});
        saveAs(blob, this.bookingsService.bookingId + '.zip');
      }, err => {
        console.log(err);
      });
  }
}

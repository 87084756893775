import {Injectable} from '@angular/core';
import {Validators} from '@angular/forms';
import {BookingsService} from '../bookings.service';
import {Booking} from '../../_models/booking';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  booking: Booking;

  constructor(private bookingsService: BookingsService) {

    if (typeof this.bookingsService.currentBooking !== 'undefined') {
      this.booking = this.bookingsService.currentBooking;
    }

    this.bookingsService.bookingLoaded.subscribe(res => {
      this.booking = this.bookingsService.currentBooking;

    });
  }


  validatorsReq() {
    if (this.booking.booking_status !== 'Draft') {
      return Validators.required;
    }
  }

  validatorsAlpha() {
    return Validators.pattern(/^[A-Za-z, \-]*$/);
  }

  validatorsAlphaReq() {
    const validators = [];
    validators.push(this.validatorsAlpha());
    if (this.booking.booking_status !== 'Draft') {
      validators.push(Validators.required);
    }
    return validators;
  }

  validatorsEmail() {
    return Validators.pattern(/^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/);
  }

  validatorsEmailReq() {
    const validators = [];
    validators.push(this.validatorsEmail());
    if (this.booking.booking_status !== 'Draft') {
      validators.push(Validators.required);
    }
    return validators;
  }

  validatorsPhone() {
    return Validators.pattern(/^1?\s?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
  }

  validatorsPhoneReq() {
    const validators = [];
    validators.push(this.validatorsPhone());
    if (this.booking.booking_status !== 'Draft') {
      validators.push(Validators.required);
    }
    return validators;
  }

  validatorsAmount() {
    return Validators.pattern(/(:?^|\s)(?=.)((?:0|(?:[1-9](?:\d*|\d{0,2}(?:,\d{3})*)))?(?:\.\d*[0-9])?)(?!\S)/);
  }

  validatorsAmountReq() {
    const validators = [];
    validators.push(this.validatorsAmount());
    if (this.booking.booking_status !== 'Draft') {
      validators.push(Validators.required);
    }
    return validators;
  }

  cleanDateStr(dateStr) {
    dateStr = dateStr.replace('00:00:00', '').trim();
    if (dateStr === '0000-00-00') return '';
    return dateStr;
  }

  floatOrEmpty(floatVal) {
    const val = parseFloat(floatVal.replace(/,/g, ''));
    if (val === 0) {
      return '';
    }
    return floatVal;
  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {OpenHouse} from '../_models/open-house';
import {AuthService} from './auth.service';
import {Task} from '../_models/task';
import {BookingsService} from './bookings.service';
import { ErrorService } from '../_services/error.service';

@Injectable({
  providedIn: 'root'
})
export class OpenHousesService {

  results: OpenHouse[];

  openHousesLoaded = new Subject<OpenHouse[]>(); // an observable to notify others a new booking has been loaded


  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private bookingsService: BookingsService,
    public errorService: ErrorService) { }

  getLastResult() {
    return this.results;

  }

  getItemFromLastResult(index) {
    return this.results[index];
  }

  findOpenHouses(bookingId): Observable<OpenHouse[]> {

    return this.http.get(`${environment.apiEndPoint}openhouses/${bookingId}`, {
      headers: this.authService.jwt()
    }).pipe(

      map(results => {
        this.results = results['openhouses'];
        return results['openhouses'];
      }
      )
    );
  }


  update(openHouseId: number, fields: {}): Promise<any> {
    return this.http.put(`${environment.apiEndPoint}openhouse/${openHouseId}`,
      fields, {
        headers: this.authService.jwt()
      }
    ).toPromise()
      .then(res => {
        if (res) {
          this.openHousesLoaded.next(this.results);
          return res;
        }
      })
      .catch(res => {
        this.errorService.displayError(res);
      });
  }


  insert(bookingId: number, fields: {}) {
    return this.http.post(`${environment.apiEndPoint}bookings/${bookingId}/openhouses`,
      fields, {
        headers: this.authService.jwt()
      }
    )
      .toPromise()
      .then(res => {
        if (res) {

            let openhouse = res['openhouses'][0];
            let openhouse_data = {id:openhouse.id, booking_id:openhouse.booking_id,open_house_date:openhouse.open_house_date.date,start_time:openhouse.start_time,end_time:openhouse.end_time,num_of_viewings:0,created_at:openhouse.created_at,updated_at:openhouse.updated_at,deleted_at:null};
            //id, booking_id open_house_date start_time, end_time, num_of_viewings, created_at , updated_at, deleted_at
      
            this.openHousesLoaded.next(this.results);
            this.bookingsService.updateOpenHouse(openhouse_data);
          return res;
        }
      })
      .catch(res => {
        this.errorService.displayError(res);
      });
  }
}

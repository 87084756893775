import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, of} from 'rxjs';

import {BehaviorSubject} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {UploadedFile} from '../../_models/uploaded-file';
import {UploadedFilesService} from '../uploaded-files.service';

export class UploadedFilesDatasource implements DataSource<UploadedFile> {

  private dataSubject = new BehaviorSubject<UploadedFile[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private myService: UploadedFilesService) {

  }

  loadData(
    type: string,
    id: number,
    filter: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number) { 

    this.loadingSubject.next(true);

    this.myService.find(type, id, filter, sortDirection,
      pageIndex, pageSize).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(data => {
        this.dataSubject.next(data);
      });

  }

  connect(collectionViewer: CollectionViewer): Observable<UploadedFile[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

}


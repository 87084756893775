import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-dialog-photos',
    templateUrl: './dialog-photos.component.html',
    styleUrls: ['./dialog-photos.component.scss']
  })

  export class DialogPhotosComponent implements OnInit {

    constructor(public thisDialogRef: MatDialogRef<DialogPhotosComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
    }

    onCloseConfirm(form) {
    // console.log(form.value);
      this.thisDialogRef.close(form.value);
    }
    onCloseCancel() {
      this.thisDialogRef.close('Cancel');
    }

  }

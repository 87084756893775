import {Component, OnInit} from '@angular/core';
import {BookingsService} from '../../../_services/bookings.service';

@Component({
  selector: 'app-status-progression-submitforreview',
  templateUrl: './status-progression-submitforreview.component.html',
  styleUrls: ['./status-progression-submitforreview.component.css']
})
export class StatusProgressionSubmitforreviewComponent implements OnInit {

  missingItemsList = '';
  missingItemsOpenHouses = '';

  allowSubmitForReview = false;

  constructor(private bookingsService: BookingsService) {
  }

  ngOnInit() {


    if (typeof this.bookingsService.currentBooking !== 'undefined') {
      this.init();
    }

    this.bookingsService.bookingLoaded.subscribe(res => {
      this.init();
    });

  }

  init() {

    this.allowSubmitForReview = this.bookingsService.allowSubmitForReview();

    this.missingItemsList = '';
    this.missingItemsList += (this.bookingsService.currentBooking.seller1_name === '') ? 'Seller Name, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.seller1_email === '') ? 'Seller Email, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.seller1_phone === '') ? 'Seller Phone, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.mls_live_date === '') ? 'MLS Live Date, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.bidding_reg_deadline_date === '') ? 'Bidding Registration Deadline ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.starting_bid_price + '' === '') ? 'Starting Bid Price, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.deposit_amount + '' === '') ? 'Deposit Amount, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.escrow_co_name === '') ? 'Escrow Company, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.escrow_co_rep_name === '') ? 'Escrow Company Rep Name, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.escrow_co_rep_email === '') ? 'Escrow Company Rep Email, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.title_co_name === '') ? 'Title Company, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.title_co_rep_name === '') ? 'Title Company Rep Name, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.title_co_rep_email === '') ? 'Title Company Rep Email, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.homewarranty_co_name === '') ? 'Home Warranty Company Name, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.homewarranty_amount + '' === '') ? 'Home Warranty Amount, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.hoa_applicable === '') ? 'HOA Applicable, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.melaroos === '') ? 'Mello-Roos, ' : '';
    this.missingItemsList += (this.bookingsService.currentBooking.property_type === '') ? 'Property Type, ' : '';

    if (this.bookingsService.currentBooking.open_houses.length === 0) {
      this.missingItemsOpenHouses = 'Add two open houses';
    } else if (this.bookingsService.currentBooking.open_houses.length === 1) {
      this.missingItemsOpenHouses = 'Add one more open house';
    }
    if (this.bookingsService.currentBooking.open_houses.length === 2) {
      this.missingItemsOpenHouses = '';
    }

  }

}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {MultiSelectModule} from 'primeng/primeng';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {AutoCompleteModule} from 'primeng/primeng';
import {LightboxModule} from 'primeng/lightbox';

import {
  MatAccordion,
  MatSelectModule,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatDialogModule, MatExpansionModule,
  MatInputModule, MatListModule, MatPaginatorModule, MatProgressSpinnerModule, MatSidenavModule,
  MatSortModule,
  MatTableModule,
  MatToolbarModule,
  MatFormFieldModule,
  MatRadioModule
} from '@angular/material';
import { FormsModule } from '@angular/forms';

import {HeaderComponent} from './header/header.component';
import {BookingComponent} from './booking/booking.component';
import {BookingEditComponent} from './booking/booking-edit/booking-edit.component';
import {BookingListComponent} from './booking/booking-list/booking-list.component';
import {HttpClientModule} from '@angular/common/http';
import {BookingsService} from './_services/bookings.service';
import {BookingInformationComponent} from './booking/booking-information/booking-information.component';
import {BookingListingComponent} from './booking/booking-listing/booking-listing.component';
import {AuthComponent} from './auth/auth.component';
import {LoginComponent} from './auth/login/login.component';
import {RegisterComponent} from './auth/register/register.component';
import {BookingSidenavComponent} from './booking/_shared/sidenav/sidenav.component';
import {BookingSidenavRightComponent} from './booking/_shared/sidenav-right/sidenav-right.component';

import {AuthService} from './_services/auth.service';
import {AuthGuardService} from './_services/auth-guard.service';
import { BookingAddDialogComponent } from './booking/booking-add-dialog/booking-add-dialog.component';
import {AuctionEventsService} from './_services/auction-events.service';
import { DisclosuresComponent } from './booking/disclosures/disclosures.component';

import {DisclosuresReportsService} from './_services/disclosures-reports.service';
import { NextBookingStatusesComponent } from './booking/_shared/next-booking-statuses/next-booking-statuses.component';
import {UploadedFilesService} from './_services/uploaded-files.service';
import { AddDisclosureDialogComponent } from './booking/disclosures/add-disclosure-dialog/add-disclosure-dialog.component';
import { UploadDisclosureDialogComponent } from './booking/disclosures/upload-disclosure-dialog/upload-disclosure-dialog.component';

import { AngularFontAwesomeModule } from 'angular-font-awesome';

// import filepond module
import { FilePondModule, registerPlugin } from 'ngx-filepond';

import { OpenHousesComponent } from './booking/open-houses/open-houses.component';
import { AddOpenHouseDialogComponent } from './booking/open-houses/add-open-house-dialog/add-open-house-dialog.component';
import {AddTaskDialogComponent} from './booking/tasks/add-task-dialog/add-task-dialog.component';
import {TasksComponent} from './booking/tasks/tasks.component';
import {ReportsComponent} from './booking/reports/reports.component';
import {AddReportDialogComponent} from './booking/reports/add-report-dialog/add-report-dialog.component';
import {UploadReportDialogComponent} from './booking/reports/upload-report-dialog/upload-report-dialog.component';
import { StatusProgressionSubmitforreviewComponent } from './booking/_shared/status-progression-submitforreview/status-progression-submitforreview.component';
import { UtilitiesService } from './_services/utils/utilities.service';
// import { SettingsComponent } from './settings/settings.component';
// import { PreferencesComponent } from './settings/preferences/preferences.component';
// import { UsersComponent } from './users/users.component';
// import { SettingsSidenavComponent } from './settings/_shared/settings-sidenav/settings-sidenav.component';
// import { MyPasswordComponent } from './settings/preferences/my-password/my-password.component';
// import { NotificationPreferencesComponent } from './settings/preferences/notification-preferences/notification-preferences.component';
import { PhotosComponent } from './booking/photos/photos.component';
import {UploadedPhotosService} from './_services/uploaded-photos.service';


import { CalendarModule, DateAdapter } from 'angular-calendar';
import { CalendarComponent } from './booking/calendar/calendar.component';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarsComponent } from './calendars/calendars.component';
import { GlobalCalendarComponent } from './calendars/global-calendar/global-calendar.component';
import {DialogModule} from 'primeng/dialog';
import {MatChipsModule} from '@angular/material/chips';
import { DialogPhotosComponent } from './booking/photos/dialog-photos/dialog-photos.component';
import { OfficesService } from './_services/offices.service';
import { RegionsService } from './_services/regions.service';
import { ListsService } from './_services/lists.service';
import {TasksGlobalComponent} from './tasks-global/tasks-global.component';
import { DocumentsComponent } from './booking/documents/documents.component';
// import { OfficesComponent } from './settings/offices/offices.component';
// import { AddOfficeComponent } from './settings/add-office/add-office.component';
import { ErrorService } from './_services/error.service';
// import { AddUserComponent } from './users/add-user/add-user.component';
// import { EditUserComponent } from './users/edit-user/edit-user.component';
import { UsersService } from './_services/users.service';
import { UserTypeService } from './_services/data.service';


import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { SoldDialogComponent } from './booking/_dialogs/sold-dialog/sold-dialog.component';
import { MeetingsComponent } from './booking/meetings/meetings.component';
import { NgxEditorModule } from 'ngx-editor';

import { DragulaModule } from 'ng2-dragula';
import { MatConfirmDialogComponent } from './booking/_dialogs/mat-confirm-dialog/mat-confirm-dialog.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

// registerPlugin(FilePondPluginImageValidateSize);
registerPlugin(FilePondPluginFileValidateType);


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BookingComponent,
    BookingEditComponent,
    BookingListComponent,
    BookingInformationComponent,
    BookingListingComponent,
    AuthComponent,
    LoginComponent,
    RegisterComponent,
    BookingSidenavComponent,
    BookingSidenavRightComponent,
    BookingAddDialogComponent,
    DisclosuresComponent,
    ReportsComponent,
    NextBookingStatusesComponent,
    AddDisclosureDialogComponent,
    UploadDisclosureDialogComponent,
    OpenHousesComponent,
    AddOpenHouseDialogComponent,
    TasksComponent,
    TasksGlobalComponent,
    AddTaskDialogComponent,
    AddReportDialogComponent,
    UploadReportDialogComponent,
    StatusProgressionSubmitforreviewComponent,
    // SettingsComponent,
    // PreferencesComponent,
    // UsersComponent,
    // SettingsSidenavComponent,
    // MyPasswordComponent,
    // NotificationPreferencesComponent,
    // EditUserComponent,
    PhotosComponent,
    CalendarComponent,
    CalendarsComponent,
    GlobalCalendarComponent,
    DialogPhotosComponent,
    DocumentsComponent,
    SoldDialogComponent,
    MeetingsComponent,
    MatConfirmDialogComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    // OfficesComponent,
    // AddOfficeComponent,
    // AddUserComponent
  ],
  imports: [
    BsDropdownModule.forRoot(),
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatCardModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    HttpClientModule,
    MatDialogModule,
    FilePondModule,
    AngularFontAwesomeModule,
    BrowserAnimationsModule,
    MultiSelectModule,
    FormsModule,
    AutoCompleteModule,
    ToastrModule.forRoot(),
    BsDropdownModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgbModalModule,
    LightboxModule,
    DialogModule,
    MatChipsModule,
    MatRadioModule,
    NgxEditorModule,
    DragulaModule.forRoot()
  ],
  providers: [
    BookingsService,
    DisclosuresReportsService,
    UploadedFilesService,
    AuctionEventsService,
    UtilitiesService,
    AuthService,
    AuthGuardService,
    UploadedPhotosService,
    OfficesService,
    RegionsService,
    ListsService,
    OfficesService,
    ErrorService,
    UsersService,
    UserTypeService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    BookingAddDialogComponent,
    AddDisclosureDialogComponent,
    UploadDisclosureDialogComponent,
    AddOpenHouseDialogComponent,
    AddTaskDialogComponent,
    AddReportDialogComponent,
    UploadReportDialogComponent,
    DialogPhotosComponent,
    SoldDialogComponent,
    MatConfirmDialogComponent
  ]
})
export class AppModule {
}

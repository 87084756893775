import { Component, OnInit } from '@angular/core';
import {AuctionEvent} from '../_models/auction-event';
import {AuctionEventsService} from '../_services/auction-events.service';
import {UsersService} from '../_services/users.service';
import {BookingsService} from '../_services/bookings.service';
import {ListsService} from '../_services/lists.service';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {

  constructor(
    private auctionEventsService: AuctionEventsService,
    private usersService: UsersService,
    private listsService: ListsService,
    private bookingservice: BookingsService) { }

  ngOnInit() {

    console.log('Getting Lists');
    this.listsService.getLists();

   // this.usersService.findListingAgents();
   // this.usersService.findRegionalDirectors();
   //  this.auctionEventsService.findEvents();
   //  this.usersService.findListingAgentsList({ filters: { 'user_type': 'Listing Agent'}});
   //  this.usersService.findRegionalDirectorsList({ filters: { 'user_type': 'Regional Director'}});
  }

}

import {Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit} from '@angular/core';
import {startOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours} from 'date-fns';
import {Subject} from 'rxjs';
import {CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView} from 'angular-calendar';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import {BookingsService} from '../../_services/bookings.service';
import {ListsService} from '../../_services/lists.service';
import {Router} from '@angular/router';

interface MyEvent extends CalendarEvent {
  booking_id: number;
}

@Component({
  selector: 'app-global-calendar-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./global-calendar.component.css'],
  templateUrl: './global-calendar.component.html'
})


export class GlobalCalendarComponent implements OnInit {
  @ViewChild('modalContent')
  modalContent: TemplateRef<any>;

  lists = {listing_agents: [], regional_directors: [], booking_statuses: [], booking_states: [], regions: [], auction_events: []};

  selectedBookingStates: string[] = [];
  selectedBookingStatuses: string[] = [];
  selectedEventTypes: string[] = [];
  selectedListingAgents: string[] = [];
  selectedOffices: string[] = [];
  selectedRegionalDirectors: string[] = [];
  selectedRegions: string[] = [];
  selectedBookings: string[] = [];


  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  bookingStates = [];
  bookingStatuses = [];
  eventTypes = [];
  listingAgents = [];
  offices = [];
  regionalDirectors = [];
  regions = [];

  refresh: Subject<any> = new Subject();
  events: MyEvent[] = [];

  activeDayIsOpen = false;


  constructor(private bookingService: BookingsService, private listsService: ListsService, private router: Router) {

  }

  ngOnInit() {

    this.listsService.getLists();

    this.fetchEvents('', '', '', '', '', '', '', '');

    // Lists
    this.listsService.dataChanged.subscribe((data) => {
      this.init();
    });
  }

  init() {


    const data = this.listsService.data;

    this.bookingStates = data.booking_states.map(function (key, index) {
      return {value: key.address_state, label: key.address_state};
    });

    this.listingAgents = data.listing_agents.map(function (key, index) {
      return {value: key.id, label: key.full_name};
    });

    this.regionalDirectors = data.regional_directors.map(function (key, index) {
      return {value: key.id, label: key.full_name};
    });

    this.bookingStatuses = data.booking_statuses.map(function (key, index) {
      return {value: key.id, label: key.name};
    });

    this.regions = data.regions.map(function (key, index) {
      return {value: key.id, label: key.region_name};
    });
  }


  fetchEvents(bookingStates, bookingStatuses, eventTypes, listingAgents, offices, regionalDirectors, regions, bookingId): void {
    this.bookingService.calendars(3, bookingStatuses, eventTypes, listingAgents, offices, regionalDirectors, regions, '').subscribe(results => {
      if (results) {
		  let Arr = [];
        for (let i = 0; i < results.length; i++) {

          const event = results[i];
           //console.log(event);

          let color = '#ad2121'; // dark red

          if (event.event_type === 'auction_date') {
            color = '#20BF55';
          } // green
          if (event.event_type === 'midcampaign_mtg_date') {
            color = '#8789C0';
          } // purple
          if (event.event_type === 'weekly_agentseller_mtg_date') {
            color = '#EBEBD3';
          } // cream
          if (event.event_type === 'regdirseller_call1_date') {
            color = '#F4D35E';
          } // yellow
          if (event.event_type === 'regdirseller_call2_date') {
            color = '#F4D35E';
          } // yellow
          if (event.event_type === 'bidding_reg_deadline_date') {
            color = '#EE964B';
          }  // yellow
          if (event.event_type === 'reserveset_date') {
            color = '#EE964B';
          } // orange
          if (event.event_type === 'mls_live_date') {
            color = '#E84855';
          }  // salmon
          if (event.event_type === 'estimated_escrow_close_date') {
            color = '#E84855';
          }  // salmon
          if (event.event_type === 'task') {
            color = '#F9B9F2';
          } // pink
          if (event.event_type === 'open_house') {
            color = '#01BAEF';
          } // blue

          this.events.push(
            {
              start: addHours(new Date(event.date), 12),
              title: event.name,
              color: {primary: color, secondary: '#000000'},
              booking_id: event.booking_id
            }
          );
			
			let data = this.eventTypes.find(ob => ob['value'] === event.event_type);

		   if(!data){
			   this.eventTypes.push({value: event.event_type, label: event.event_type});
		   }
			
		  
			 
           this.refresh.next();
        }
      }
    });
  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }


  filterSelect() {

    const bookingStates = this.selectedBookingStates.toString();
    const bookingStatuses = this.selectedBookingStatuses.toString();
    const eventTypes = this.selectedEventTypes.toString();
    const listingAgents = this.selectedListingAgents.toString();
    const offices = this.selectedOffices.toString();
    const regionalDirectors = this.selectedRegionalDirectors.toString();
    const regions = this.selectedRegions.toString();
    this.events = [];
    this.fetchEvents(bookingStates, bookingStatuses, eventTypes, listingAgents, offices, regionalDirectors, regions, '');
  }

  eventClicked(event) {
    this.router.navigate(['/bookings', + event.booking_id, 'listing']);
  }


}

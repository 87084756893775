import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UsersService} from '../_services/users.service';
import {BookingsService} from '../_services/bookings.service';
import 'rxjs';
import {AuctionEvent} from '../_models/auction-event';
import {AuctionEventsService} from '../_services/auction-events.service';
import {OfficesService} from '../_services/offices.service';

@Component({
  selector: 'app-calendars',
  templateUrl: './calendars.component.html',
  styleUrls: ['./calendars.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarsComponent implements OnInit {
  auctionEvents: AuctionEvent[];

  constructor() {
  }

  ngOnInit() {
  }

}

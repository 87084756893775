// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // apiEndPoint: 'https://manage.myauction.harcourtsauctions.com/api/',
  // apiEndPointUnAuth: 'https://manage.myauction.harcourtsauctions.com/api/auth/',
  //
  // managerUrl: 'https://manage.myauction.harcourtsauctions.com/'

  //
  // apiEndPoint: 'https://calm-belgrade-yruqxk0wrtew.vapor-farm-e1.com/api/',
  // apiEndPointUnAuth: 'https://calm-belgrade-yruqxk0wrtew.vapor-farm-e1.com/api/auth/',
  // managerUrl: 'https://calm-belgrade-yruqxk0wrtew.vapor-farm-e1.com/'
  apiEndPoint: 'https://manage.myauction.harcourtsauctions.com/api/',
  apiEndPointUnAuth: 'https://manage.myauction.harcourtsauctions.com/api/auth/',
  managerUrl: 'https://manage.myauction.harcourtsauctions.com/'

  // apiEndPoint: 'http://127.0.0.1:8000/api/',
  // apiEndPointUnAuth: 'http://127.0.0.1:8000/api/auth/',
  // managerUrl: 'http://127.0.0.1:8000/'
};

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { OpenHousesService } from '../../../_services/open-houses.service';
import { BookingsService } from '../../../_services/bookings.service';

@Component({
  selector: 'app-add-open-house-dialog',
  templateUrl: './add-open-house-dialog.component.html',
  styleUrls: ['./add-open-house-dialog.component.css']
})
export class AddOpenHouseDialogComponent implements OnInit {

  editMode = false;

  addOpenHouseForm: FormGroup;
  minDate : any;

  constructor(
    private openHousesService: OpenHousesService,
    private bookingsService: BookingsService,
    private dialogRef: MatDialogRef<AddOpenHouseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    const editMode = this.editMode = (this.data == null) ? false : true;
    this.addOpenHouseForm = new FormGroup({
      'id': new FormControl(editMode ? this.data.id : ''),
      'open_house_date': new FormControl(editMode ? this.data.open_house_date : ''),
      'start_time': new FormControl(editMode ? this.data.start_time : ''),
      'end_time': new FormControl(editMode ? this.data.end_time : ''),
      'num_of_viewings': new FormControl(editMode ? this.data.num_of_viewings : '')
    },{validators: this.matchTime});

    this.minDate = this.bookingsService.currentBooking.mls_live_date;
    //console.log('book',this.bookingsService);

  }

  matchTime(group: FormGroup) { // here we have the 'passwords' group
  let start_time = group.get('start_time').value;
  let end_time = group.get('end_time').value;
  console.log(start_time)
  if(start_time != '' && end_time != ''){
    return start_time < end_time ? null : { notSame: true } 
  }    
}


  onSaveOpenHouse() {
    const fields = this.addOpenHouseForm.value;

    if (this.addOpenHouseForm.invalid) {
      //this.toastr.error('Please Match Password and Confirm Password.', 'Reset Password');
      return;
    }

    if (this.editMode) {
      this.openHousesService.update(fields.id, fields).then((res) => {
        if (res !== undefined) {
          this.dialogRef.close();
        }
      });
    } else {
      this.openHousesService.insert(this.bookingsService.bookingId, fields).then((res) => {
        if (res !== undefined) {
          this.dialogRef.close();
        }
      }
      );
    }
  }

  onCancel() {

  }

}

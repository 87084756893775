import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BookingComponent} from './booking/booking.component';
import {BookingEditComponent} from './booking/booking-edit/booking-edit.component';
import {BookingListComponent} from './booking/booking-list/booking-list.component';
import {BookingInformationComponent} from './booking/booking-information/booking-information.component';
import {BookingListingComponent} from './booking/booking-listing/booking-listing.component';
import {LoginComponent} from './auth/login/login.component';
import {RegisterComponent} from './auth/register/register.component';
import {ForgotPasswordComponent} from './auth/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './auth/reset-password/reset-password.component';
import {AuthComponent} from './auth/auth.component';
import {AuthGuardService} from './_services/auth-guard.service';
import {DisclosuresReportsService} from './_services/disclosures-reports.service';
import {DisclosuresComponent} from './booking/disclosures/disclosures.component';
import {ReportsComponent} from './booking/reports/reports.component';
import {OpenHousesComponent} from './booking/open-houses/open-houses.component';
import {TasksComponent} from './booking/tasks/tasks.component';
import {TasksGlobalComponent} from './tasks-global/tasks-global.component';
// import {SettingsComponent} from './settings/settings.component';
// import {PreferencesComponent} from './settings/preferences/preferences.component';
// import {MyPasswordComponent} from './settings/preferences/my-password/my-password.component';
// import {NotificationPreferencesComponent} from './settings/preferences/notification-preferences/notification-preferences.component';
// import {UsersComponent} from './users/users.component';
// import {EditUserComponent} from './users/edit-user/edit-user.component';
import {PhotosComponent} from './booking/photos/photos.component';
import {CalendarComponent} from './booking/calendar/calendar.component';
import {CalendarsComponent} from './calendars/calendars.component';
import {GlobalCalendarComponent} from './calendars/global-calendar/global-calendar.component';
import {DocumentsComponent} from './booking/documents/documents.component';
import {MeetingsComponent} from './booking/meetings/meetings.component';
// import {OfficesComponent} from './settings/offices/offices.component';
// import {AddOfficeComponent} from './settings/add-office/add-office.component';
// import {AddUserComponent} from './users/add-user/add-user.component';
import { CanDeactivateGuard }    from './can-deactivate.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {path: 'login', component: LoginComponent},
      {path: 'register', component: RegisterComponent},
      {path: 'forgot-password', component: ForgotPasswordComponent},
      {path: 'reset-password/:token', component: ResetPasswordComponent}
    ]
  },
  {
    path: 'bookings',
    component: BookingComponent,
    canActivateChild: [AuthGuardService],
    runGuardsAndResolvers: 'paramsChange',
    children: [
      {path: '', component: BookingListComponent},
      {
        path: ':id', component: BookingEditComponent, children: [
          {path: 'information', component: BookingInformationComponent,canDeactivate: [CanDeactivateGuard]},
          {path: 'listing', component: BookingListingComponent, canDeactivate: [CanDeactivateGuard]},
          {path: 'disclosures', component: DisclosuresComponent},
          {path: 'photos', component: PhotosComponent},
          {path: 'reports', component: ReportsComponent},
          {path: 'openhouses', component: OpenHousesComponent},
          {path: 'tasks', component: TasksComponent},
          {path: 'meetings', component: MeetingsComponent},
          {path: 'calendar', component: CalendarComponent},
          {path: 'documents', component: DocumentsComponent}
        ]
      },
    ]
  },
  {
    path: 'calendars',
    component: CalendarsComponent,
    canActivateChild: [AuthGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {path: '', component: GlobalCalendarComponent}
    ]
  },
  {
    path: 'tasks',
    component: TasksGlobalComponent,
    canActivateChild: [AuthGuardService],
    children: [
      {path: '', component: TasksComponent}
    ]
  },
  // {
  //   path: 'offices',
  //   component: SettingsComponent,
  //   canActivateChild: [AuthGuardService],
  //   children: [
  //     {path: '', component: OfficesComponent},
  //     {path: 'new', component: AddOfficeComponent},
  //     {path: ':id', component: AddOfficeComponent}
  //   ]
  // },
  // {
  //   path: 'settings',
  //   component: SettingsComponent,
  //   canActivateChild: [AuthGuardService],
  //   children: [
  //     {path: '', component: PreferencesComponent},
  //     {path: 'preferences', component: PreferencesComponent},
  //     {path: 'preferences/password', component: MyPasswordComponent},
  //     {path: 'preferences/notifications', component: NotificationPreferencesComponent}
  //   ]
  // },
  // {
  //   path: 'users',
  //   component: SettingsComponent,
  //   canActivateChild: [AuthGuardService],
  //   children: [
  //     {path: '', component: UsersComponent},
  //     {path: 'listing-agents', component: UsersComponent},
  //     {path: 'regional-directors', component: UsersComponent},
  //     {path: 'managing-admins', component: UsersComponent},
  //     {path: 'super-admins', component: UsersComponent},
  //     {path: 'new', component: AddUserComponent},
  //     {path: ':id', component: EditUserComponent}
  //   ]
  // },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes),
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})
  ],
  exports: [
    RouterModule,

  ]
})
export class AppRoutingModule {
}
